// pdfGenerator.ts

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { UserOptions } from "jspdf-autotable";
import html2canvas from 'html2canvas';

declare module 'jspdf' {
  interface jsPDF {
    autoTable: (options: UserOptions) => jsPDF;
  }
}

interface Service {
  name: string;
  displayName?: string;
  description: string;
  quotePageDesc?: string;
  fineText?: string;
}

interface Quote {
  quoteId: string;
  services: string[];
  serviceValues: string[];
  totalValue: number;
  status: string;
  acceptedTime?: string;
}

interface QuoteTemplate {
  name: string;
  introParagraph: string;
  companyName: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
  };
}

interface CustomerData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature?: string;
}

export const generateQuotePDF = async (
  quote: Quote,
  template: QuoteTemplate,
  customerData: CustomerData,
  viewportRatio: number, // Moved before optional parameters
  companyLogo?: string,
  predefinedServices?: Service[]
): Promise<Blob> => {
  console.log("Starting PDF generation...");
  console.log("Quote Data:", quote);
  console.log("Template Data:", template);
  console.log("Customer Data:", customerData);
  console.log("Viewport Ratio:", viewportRatio);
  console.log("Company Logo URL:", companyLogo);
  console.log("Predefined Services:", predefinedServices);

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  console.log(`Page dimensions: ${pageWidth}pt x ${pageHeight}pt`);

  // Define margins
  const margin = 60;
  const bottomMargin = 60;

  // Define padding between logo and next content
  const logoPadding = 40;

  // Function to draw dashed line
  const drawDashedLine = (startX: number, endX: number, y: number, dashLength: number = 3) => {
    console.log(`Drawing dashed line from (${startX}, ${y}) to (${endX}, ${y})`);
    doc.setLineDashPattern([dashLength], 0); // Add 0 as the dash phase
    doc.setLineWidth(0.5);
    doc.setDrawColor(200, 200, 200); // Light gray color
    doc.line(startX, y - 2, endX, y - 2);
    doc.setLineDashPattern([], 0); // Reset dash pattern with phase
  };

  try {
    // Set font
    const font = template.customization.fontFamily?.toLowerCase() === 'helvetica' ? 'helvetica' : 'helvetica';
    console.log(`Setting font to: ${font}`);
    doc.setFont(font);
    /* console.log('VIEWPORT RATIO: ', viewportRatio); */

    // Initialize yPos with default value
    let yPos = 150;
    console.log(`Initial yPos set to: ${yPos}`);

    // Add company logo
    if (companyLogo) {
      console.log("Company logo provided. Attempting to add to PDF...");
      try {
        const logoWidth = 120; // Desired logo width in points
        const logoX = (pageWidth - logoWidth) / 2; // Center the logo horizontally
        const logoY = 50; // Y-position of the logo

        console.log(`Logo dimensions and position: Width=${logoWidth}, X=${logoX}, Y=${logoY}`);

        // Fetch the logo image from the URL
        console.log(`Fetching logo from URL: ${companyLogo}`);
        const response = await fetch(companyLogo);
        if (!response.ok) {
          throw new Error(`Failed to fetch logo image: ${response.statusText}`);
        }

        // Convert the image to a blob
        console.log("Converting logo image to blob...");
        const blob = await response.blob();

        // Convert the blob to a Data URL (base64)
        console.log("Converting blob to Data URL...");
        const dataURL = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              resolve(reader.result);
            } else {
              reject(new Error("Failed to convert logo image to Data URL"));
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading logo image blob"));
          };
          reader.readAsDataURL(blob);
        });

        console.log("Data URL obtained for logo.");

        // Create an Image object to retrieve its natural dimensions
        const img = new Image();
        img.src = dataURL;

        // Wait for the image to load
        console.log("Loading image to retrieve natural dimensions...");
        await new Promise<void>((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = () =>
            reject(new Error("Failed to load image for dimension calculation"));
        });

        console.log("Image loaded successfully.");

        // Calculate the logo height while maintaining aspect ratio
        const aspectRatio = img.naturalHeight / img.naturalWidth;
        const logoHeight = logoWidth * aspectRatio;
        console.log(`Calculated logo height based on aspect ratio (${aspectRatio}): ${logoHeight}`);

        // Add the image to the PDF with calculated dimensions
        console.log("Adding logo image to PDF...");
        doc.addImage(dataURL, "PNG", logoX, logoY, logoWidth, logoHeight);

        // Update yPos based on the logo's position and height, plus padding
        yPos = logoY + logoHeight + logoPadding;
        console.log(`Updated yPos after adding logo: ${yPos}`);
      } catch (error) {
        console.error("Error loading logo from URL:", error);
        // Optionally, you can add a placeholder logo or skip adding the logo
        yPos = 150; // Reset to default if logo fails
        console.log(`yPos reset to default: ${yPos}`);
      }
    } else {
      console.log("No company logo provided. Skipping logo addition.");
    }

    // Determine device type based on viewportRatio
    const isBrowser = viewportRatio < 2.5; // Adjust threshold as needed
    const descriptionFontSize = isBrowser ? '34px' : '8px';
    console.log(`Determined device type: ${isBrowser ? "Browser" : "Other"} with description font size: ${descriptionFontSize}`);

    // Add services
    console.log("Starting to add services to PDF...");
    for (const serviceName of quote.services) {
      console.log(`Processing service: ${serviceName}`);
      const index = quote.services.indexOf(serviceName);
      const serviceValue = quote.serviceValues[index];
      console.log(`Service index: ${index}, Service value: ${serviceValue}`);

      // Fetch service details based on 'displayName' if it exists, else 'name'
      let serviceDetails: Service = {
        name: serviceName,
        description: '',
        fineText: ''
      };

      const customService = template.customServices.find((s) => s.displayName === serviceName);
      if (customService) {
        serviceDetails = customService;
        console.log(`Found custom service by displayName:`, serviceDetails);
      } else if (predefinedServices) {
        const predefinedService = predefinedServices.find((s) => s.displayName === serviceName);
        if (predefinedService) {
          serviceDetails = predefinedService;
          console.log(`Found predefined service by displayName:`, serviceDetails);
        } else {
          // Fallback to finding by 'name'
          const fallbackCustomService = template.customServices.find((s) => s.name === serviceName);
          const fallbackPredefinedService = predefinedServices.find((s) => s.name === serviceName);
          if (fallbackCustomService) {
            serviceDetails = fallbackCustomService;
            console.log(`Found custom service by name:`, serviceDetails);
          } else if (fallbackPredefinedService) {
            serviceDetails = fallbackPredefinedService;
            console.log(`Found predefined service by name:`, serviceDetails);
          } else {
            console.log(`No service found for "${serviceName}", using default empty service.`);
          }
        }
      }

      console.log(`Final service details for "${serviceName}":`, serviceDetails);

      // Determine the display name: use 'displayName' if available, else 'name'
      const displayServiceName = serviceDetails.displayName || serviceDetails.name;
      console.log(`Display Service Name: ${displayServiceName}`);

      // Add service name
      doc.setFontSize(16);
      doc.setTextColor(template.customization.secondaryColor || '#000000');
      const serviceNameWidth = doc.getTextWidth(displayServiceName);
      console.log(`Service Name Width: ${serviceNameWidth}`);
      doc.text(displayServiceName, margin, yPos);
      console.log(`Added service name "${displayServiceName}" at position (${margin}, ${yPos})`);

      // Add service value
      const valueText = `$${serviceValue}`;
      doc.setFontSize(12);
      const valueWidth = doc.getTextWidth(valueText);
      doc.setTextColor('#000000');
      doc.text(valueText, pageWidth - margin, yPos, { align: 'right' });
      console.log(`Added service value "${valueText}" at position (${pageWidth - margin}, ${yPos})`);

      // Draw dashed line between service name and value
      const lineStartX = margin + serviceNameWidth + 10; // Start 10pt after service name
      const lineEndX = pageWidth - margin - valueWidth - 10; // End 10pt before value
      console.log(`Dashed line startX: ${lineStartX}, endX: ${lineEndX}, y: ${yPos}`);
      drawDashedLine(lineStartX, lineEndX, yPos);

      yPos += 20;
      console.log(`Updated yPos after adding service line: ${yPos}`);

      // Add service description
      let serviceDescription = serviceDetails.quotePageDesc || serviceDetails.description;
      console.log(`Service Description: ${serviceDescription}`);

      if (serviceDescription) {
        doc.setFontSize(14);
        console.log("Adding service description...");

        const serviceDescriptionHTML = `
          <div style="font-size:${descriptionFontSize}; color:#1E1F20;">
            ${serviceDescription}
          </div>
        `;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = serviceDescriptionHTML;
        document.body.appendChild(tempDiv);
        console.log("Temporary div created for service description.");

        const canvas = await html2canvas(tempDiv, { scale: 3 });
        console.log("html2canvas rendered service description to canvas.");
        const imgData = canvas.toDataURL('image/jpeg', 0.7);
        console.log("Converted canvas to image data URL.");

        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        console.log(`Image dimensions: Width=${imgWidth}, Height=${imgHeight}`);

        doc.addImage(imgData, 'JPEG', margin, yPos, imgWidth, imgHeight);
        console.log(`Added service description image to PDF at (${margin}, ${yPos})`);

        yPos += imgHeight + 40;
        console.log(`Updated yPos after adding service description: ${yPos}`);

        document.body.removeChild(tempDiv);
        console.log("Temporary div removed after adding service description.");
      }
    }

    // Add total with dashed line
    console.log("Adding total value to PDF...");
    doc.setFontSize(14);
    doc.setTextColor(template.customization.primaryColor || '#000000');
    const totalText = 'Total:';
    const totalValue = `$${quote.totalValue.toFixed(2)}`;
    const totalTextWidth = doc.getTextWidth(totalText);
    const totalValueWidth = doc.getTextWidth(totalValue);
    console.log(`Total Text: "${totalText}", Total Value: "${totalValue}"`);
    console.log(`Total Text Width: ${totalTextWidth}, Total Value Width: ${totalValueWidth}`);

    doc.text(totalText, margin, yPos + 10);
    doc.text(totalValue, pageWidth - margin, yPos + 10, { align: 'right' });
    console.log(`Added total at positions (${margin}, ${yPos + 10}) and (${pageWidth - margin}, ${yPos + 10})`);

    /* // Draw dashed line for total
    const totalLineStartX = margin + totalTextWidth + 10;
    const totalLineEndX = pageWidth - margin - totalValueWidth - 10;
    drawDashedLine(totalLineStartX, totalLineEndX, yPos + 10); */

    yPos += 30;
    console.log(`Updated yPos after adding total: ${yPos}`);

    // Define positions for signature and terms
    const termsY = pageHeight - bottomMargin;
    const signatureY = termsY - 200;
    const signatureWidth = 150;
    const signatureHeight = 50;
    const signatureX = pageWidth - margin - signatureWidth;
    console.log(`Signature position and size: X=${signatureX}, Y=${signatureY}, Width=${signatureWidth}, Height=${signatureHeight}`);
    console.log(`Terms Y position: ${termsY}`);

    // Add signature if quote is accepted
    if (quote.status === 'Accepted' && customerData.signature) {
      console.log("Quote is accepted and signature is provided. Adding signature to PDF...");
      doc.addImage(customerData.signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
      console.log(`Added signature image at (${signatureX}, ${signatureY}) with size (${signatureWidth}x${signatureHeight})`);

      doc.setFontSize(10);
      doc.setTextColor('#000000');
      const signedByText = `Signed by ${customerData.firstName} ${customerData.lastName}`;
      const acceptedOnText = `Accepted on ${new Date(quote.acceptedTime!).toLocaleString()}`;
      console.log(`Signed by text: "${signedByText}", Accepted on text: "${acceptedOnText}"`);
      doc.text(signedByText, pageWidth - margin, signatureY + signatureHeight + 15, { align: 'right' });
      doc.text(acceptedOnText, pageWidth - margin, signatureY + signatureHeight + 30, { align: 'right' });
      console.log("Added signed by and accepted on texts.");
    } else {
      console.log("Quote is not accepted or signature is missing. Skipping signature addition.");
    }

    // Add terms and conditions
    if (quote.services.length > 0) {
      console.log("Adding terms and conditions to PDF...");
      let termsContent = '';
      quote.services.forEach((serviceName) => {
        const serviceDetails = template.customServices.find((s) => s.displayName === serviceName) ||
                               predefinedServices?.find((s) => s.displayName === serviceName) || {
          name: serviceName,
          description: '',
          fineText: ''
        };
        if (serviceDetails.fineText) {
          termsContent += `${serviceDetails.fineText}\n\n`;
        }
      });

      console.log(`Compiled terms content: "${termsContent}"`);

      if (termsContent) {
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        doc.text('Terms and Conditions:', margin, termsY - 80);
        console.log("Added 'Terms and Conditions:' header.");

        doc.setFontSize(9);
        const fineTextLines = doc.splitTextToSize(termsContent, pageWidth - 2 * margin);
        doc.text(fineTextLines, margin, termsY - 60);
        console.log("Added terms and conditions text.");

        doc.setLineWidth(0.5);
        doc.line(margin, termsY - 110, pageWidth - margin, termsY - 110);
        console.log(`Drew dashed line for terms and conditions at y=${termsY - 110}`);
      } else {
        console.log("No terms content to add.");
      }
    } else {
      console.log("No services in quote. Skipping terms and conditions addition.");
    }

    console.log("PDF generation completed successfully.");
    return doc.output('blob');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF');
  }
};