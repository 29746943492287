// frontend/src/components/Login.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useAuth } from '../AuthContext';
import CowboyHatLogo from './CowboyHatLogo';
import '../App.css';
import { Alert } from '@mui/material'; // Ensure to import Alert for error messages

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axiosInstance.post('/login', {
        email,
        password
      });
      console.log('Login successful', response.data);

      // Store the token in localStorage
      localStorage.setItem('token', response.data.token);

      // Store user data in localStorage
      const userData = {
        userId: response.data.userId,
        name: response.data.name,
        email: response.data.email,
        companyId: response.data.companyId,
        companyName: response.data.companyName,
        cleanedCompanyName: response.data.cleanedCompanyName, // Ensure this field is returned from backend
        role: response.data.role,
        planTier: response.data.planTier, // Ensure this field is returned from backend
      };
      localStorage.setItem('userData', JSON.stringify(userData));

      // Update the authentication state
      setIsAuthenticated(true);

      // Navigate to dashboard
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Login failed. Please check your credentials.');
      console.error('Login error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container login-container">
      <CowboyHatLogo /> {/* Ensure CowboyHatLogo redirects to home on click */}
      <h2>Login</h2>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        {/* Email Input */}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </div>

        {/* Password Input */}
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Enter your password"
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      <p>
        <a href="/forgot-password">Forgot Password?</a>
      </p>
    </div>
  );
};

export default Login;