// ViewAllQuotes.tsx

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import {
  Search,
  OpenInNew,
  Info,
  QrCode as QrCodeIcon,
  ArrowBack,
  ExpandMore,
  ExpandLess,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import DropdownMenu from './DropdownMenu';
import zIndex from '@mui/material/styles/zIndex';

interface Quote {
  quoteId: string;
  templateId: string;
  templateName?: string;
  companyName: string;
  customerName?: string;
  services: string[];
  serviceValues: string[];
  totalValue: number;
  status: 'Idle' | 'Active' | 'Accepted' | 'Rejected';
  activatedTime: string;
  acceptedTime: string;
  expirationTime: string;
  qrUrl: string;
  concatenatedQuoteId?: string; 
  isViewed: boolean;
  firstViewedAt?: string | null;

}

interface UserData {
  userId: string;
  name: string;
  email: string;
  companyId: string;
  companyName: string;
  role: string;
}

interface Column {
  id: string;
  label: string;
  align: TableCellProps['align']; // Restricts align to valid values
  sortable: boolean;
}

const ViewAllQuotes: React.FC = () => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [selectedQuotes, setSelectedQuotes] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Quote; direction: 'asc' | 'desc' }>({
    key: 'activatedTime',
    direction: 'desc',
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();

  // Responsive Hook
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQuotes = async () => {
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) {
        enqueueSnackbar('User data not found', { variant: 'error' });
        return;
      }

      const userData: UserData = JSON.parse(userDataString);
      if (!userData.userId) {
        enqueueSnackbar('User ID not found', { variant: 'error' });
        return;
      }

      const response = await axiosInstance.get(`/api/quotes/all/${userData.userId}`);
      const quotesWithConcatenatedId: Quote[] = response.data.map((quote: Quote) => ({
        ...quote,
        concatenatedQuoteId: generateConcatenatedId(quote.quoteId),
      }));
      setQuotes(quotesWithConcatenatedId);
    } catch (error) {
      console.error('Error fetching quotes:', error);
      enqueueSnackbar('Failed to fetch quotes', { variant: 'error' });
    }
  };

  const generateConcatenatedId = (quoteId: string): string => {
    const parts = quoteId.split('_');
    if (parts.length < 4) return 'Invalid ID';
    const userId = parts[2]?.slice(-2) || '00';
    const templateId = parts[3]?.slice(-2) || '00';
    const quoteNumber = parts[parts.length - 1] || '0000';
    return `${userId}${templateId}${quoteNumber}`;
  };

  const handleSort = (key: keyof Quote) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedQuotes = React.useMemo(() => {
    const sortableQuotes = [...quotes];
    if (sortConfig.key) {
      sortableQuotes.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
  
        // Handle different data types
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
  
        if (sortConfig.key === 'activatedTime' || sortConfig.key === 'acceptedTime') {
          const aDate = new Date(aValue as string).getTime();
          const bDate = new Date(bValue as string).getTime();
          return sortConfig.direction === 'asc' ? aDate - bDate : bDate - aDate;
        }
  
        if (sortConfig.key === 'isViewed') {
          // Sort false before true for ascending
          if (sortConfig.direction === 'asc') {
            return Number(aValue) - Number(bValue);
          } else {
            return Number(bValue) - Number(aValue);
          }
        }
  
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
  
        return 0;
      });
    }
    return sortableQuotes;
  }, [quotes, sortConfig]);

  const filteredQuotes = sortedQuotes.filter(
    (quote) =>
      (quote.templateName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (quote.customerName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (quote.concatenatedQuoteId?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  // Grouping Quotes by templateName
  const sortedAndGroupedQuotes = React.useMemo(() => {
    const sorted = [...filteredQuotes].sort((a, b) =>
      a.templateName?.localeCompare(b.templateName || '') || 0
    );
    const groups: { [key: string]: Quote[] } = {};
    sorted.forEach((quote) => {
      const group = quote.templateName || 'Unknown Template';
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(quote);
    });
    return groups;
  }, [filteredQuotes]);

  const handleToggleGroup = (templateName: string) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [templateName]: !prev[templateName],
    }));
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allQuoteIds = filteredQuotes.map((q) => q.quoteId);
      setSelectedQuotes(allQuoteIds);
    } else {
      setSelectedQuotes([]);
    }
  };

  const handleSelect = (quoteId: string) => {
    setSelectedQuotes((prev) =>
      prev.includes(quoteId) ? prev.filter((id) => id !== quoteId) : [...prev, quoteId]
    );
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.post('/api/quotes/deleteMultiple', { quoteIds: selectedQuotes });
      enqueueSnackbar('Selected quotes deleted successfully', { variant: 'success', autoHideDuration: 1750 });
      setSelectedQuotes([]);
      setIsDeleteDialogOpen(false);
      fetchQuotes(); // Refresh the quotes data to reflect deletions
    } catch (error) {
      console.error('Error deleting quotes:', error);
      enqueueSnackbar('Error deleting quotes', { variant: 'error' });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleViewCustomerQuote = (quoteId: string, companyName: string) => {
    window.open(`/quote/${quoteId}/${companyName}`, '_blank');
  };

  const handleViewQuote = (quoteId: string) => {
    navigate(`../internal/quote/${quoteId}`);
  };

  const calculateDaysUntilExpired = (activatedTime: string, expirationTime: string): string => {
    const now = new Date();
    const expTime = new Date(expirationTime);
    const actTime = new Date(activatedTime);
    const referenceTime = actTime > now ? actTime : now;
    const diffTime = expTime.getTime() - referenceTime.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 0) {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} remaining`;
    } else if (diffDays === 0) {
      return 'Expires today';
    } else {
      return 'Expired';
    }
  };

  // Pagination Handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Define styles for the group header based on the theme mode
  const groupHeaderStyles = {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
    zIndex:0
  };

  // Define column configurations for consistency
  const columns: Column[] = [
    { id: 'checkbox', label: '', align: 'center', sortable: false },
    { id: 'templateName', label: 'Template Name', align: 'center', sortable: true },
    { id: 'concatenatedQuoteId', label: 'Quote ID', align: 'center', sortable: true },
    { id: 'customerName', label: 'Customer Name', align: 'center', sortable: true },
    { id: 'activatedTime', label: 'Activated @', align: 'center', sortable: true },
    { id: 'acceptedTime', label: 'Accepted @', align: 'center', sortable: true },
    { id: 'expiresIn', label: 'Expires In', align: 'center', sortable: false },
    { id: 'status', label: 'Status', align: 'center', sortable: true },
    { id: 'isViewed', label: 'Viewed Status', align: 'center', sortable: true }, 
    { id: 'totalValue', label: 'Total Value', align: 'center', sortable: true },
    { id: 'actions', label: 'Actions', align: 'center', sortable: false },
  ];

  return (
    <Box sx={{ padding: 3, overflowX: 'hidden' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate('/dashboard/quotes')}
          sx={{ width: 30, mr: 1 }}
          color="warning"
        >
          {/* Optional: Add text like "Back" */}
        </Button>
        <Typography variant="h4">All Quotes</Typography>
      </Box>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 2,
        }}
      >
        <TextField
          placeholder="Search quotes..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ 
            flex: 1, 
            maxWidth: { xs: '100%', sm: '300px' },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'warning.main',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'warning.main', 
            },
          }}


        />
        <DropdownMenu
          options={[{ label: 'Delete Selected Quotes', onClick: () => setIsDeleteDialogOpen(true) }]}
          buttonText="Actions"
          disabled={selectedQuotes.length === 0 || isDeleting}
        />
      </Box>
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ tableLayout: 'auto', minWidth: 650 }}>
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 0 }}> 
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      cursor: column.sortable ? 'pointer' : 'default',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => column.sortable && handleSort(column.id as keyof Quote)}
                  >
                    {column.label}
                    {/* Display sort indicators */}
                    {column.sortable && sortConfig.key === column.id && (
                      <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(sortedAndGroupedQuotes).map((templateName) => (
                <React.Fragment key={templateName}>
                  {/* Group Header */}
                  <TableRow>
                    <TableCell colSpan={columns.length} sx={{ ...groupHeaderStyles, borderBottom: 'none' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                        onClick={() => handleToggleGroup(templateName)}
                      >
                        <IconButton size="small" sx={{ color: groupHeaderStyles.color, width: 30 }}>
                          {expandedGroups[templateName] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', ml: 1 }}>
                          {templateName}
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, color: theme.palette.text.secondary }}>
                          ({sortedAndGroupedQuotes[templateName].length} quote
                          {sortedAndGroupedQuotes[templateName].length !== 1 ? 's' : ''})
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>

                  {/* Collapsible Quote Rows */}
                  {expandedGroups[templateName] &&
                    sortedAndGroupedQuotes[templateName]
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((quote) => (
                        <TableRow key={quote.quoteId}>
                          {columns.map((column) => {
                            // Conditionally hide certain columns on small screens
                            if (isSmallScreen && (column.id === 'actions' || column.id === 'qrUrl')) {
                              return null; // Skip rendering this cell
                            }

                            switch (column.id) {
                              case 'checkbox':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Checkbox
                                      checked={selectedQuotes.includes(quote.quoteId)}
                                      onChange={() => handleSelect(quote.quoteId)}
                                      sx={{
                                        '&.Mui-checked': {
                                          color: 'warning.main',
                                        },
                                      }}
                                    />
                                  </TableCell>
                                );
                              case 'templateName':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Typography
                                      variant="body2"
                                      noWrap
                                      title={quote.templateName}
                                      sx={{ maxWidth: '150px', wordBreak: 'break-word' }}
                                    >
                                      {quote.templateName || 'N/A'}
                                    </Typography>
                                  </TableCell>
                                );
                              case 'concatenatedQuoteId':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Tooltip
                                      title={`Full Quote ID: ${quote.quoteId}`}
                                      arrow
                                      PopperProps={{
                                        style: { zIndex: 10000 },
                                      }}
                                    >
                                      <span>{quote.concatenatedQuoteId || 'N/A'}</span>
                                    </Tooltip>
                                  </TableCell>
                                );
                              case 'customerName':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {quote.acceptedTime ? quote.customerName : 'N/A'}
                                  </TableCell>
                                );
                              case 'activatedTime':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {quote.activatedTime
                                      ? new Date(quote.activatedTime).toLocaleString()
                                      : 'N/A'}
                                  </TableCell>
                                );
                              case 'acceptedTime':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {quote.acceptedTime
                                      ? new Date(quote.acceptedTime).toLocaleString()
                                      : 'N/A'}
                                  </TableCell>
                                );
                              case 'expiresIn':
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {quote.status === 'Accepted' ? (
                                    'N/A'
                                  ) : quote.activatedTime ? (
                                    calculateDaysUntilExpired(quote.activatedTime, quote.expirationTime)
                                  ) : (
                                    'N/A'
                                  )}
                                </TableCell>
                              );
                              case 'status':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {quote.status}
                                  </TableCell>
                                );

                                case 'isViewed': // New Case
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {quote.isViewed ? (
                                      <Tooltip
                                        title={
                                          quote.firstViewedAt
                                            ? `First viewed: ${new Date(quote.firstViewedAt).toLocaleString()}`
                                            : 'View date not available'
                                        }
                                        arrow
                                        PopperProps={{
                                          style: { zIndex: 10000 }, // Ensure it's above the modal
                                        }}
                                      >
                                        <Visibility color="success" />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Not viewed yet">
                                        <VisibilityOff color="disabled" />
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                );

                              case 'totalValue':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    ${quote.totalValue.toFixed(2)}
                                  </TableCell>
                                );
                              case 'actions':
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Box sx={{ display: 'flex', justifyContent: 'left', gap: 1 }}>
                                      <Tooltip
                                        title={
                                          quote.status === 'Idle'
                                            ? 'Activate to view landing page'
                                            : 'View Customer Quote Page'
                                        }
                                        PopperProps={{
                                          style: { zIndex: 10000 },
                                        }}
                                      >
                                        <span>
                                          <IconButton
                                            onClick={() => handleViewCustomerQuote(quote.quoteId, quote.companyName)}
                                            size="small"
                                            disabled={quote.status === 'Idle'}
                                            sx={{ width: 40, height: 40 }}
                                          >
                                            <OpenInNew />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          quote.status === 'Idle' ? 'Cannot view idle quotes' : 'View Internal Quote'
                                        }
                                        PopperProps={{
                                          style: { zIndex: 10000 },
                                        }}
                                      >
                                        <span>
                                          <IconButton
                                            onClick={() => handleViewQuote(quote.quoteId)}
                                            disabled={quote.status === 'Idle'}
                                            size="small"
                                            sx={{ width: 40, height: 40 }}
                                          >
                                            <Info />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      {quote.status !== 'Accepted' && (
                                        <Tooltip
                                          title={
                                            <img
                                              src={quote.qrUrl}
                                              alt="QR Code"
                                              style={{ width: '150px', height: '150px' }}
                                            />
                                          }
                                          PopperProps={{
                                            style: { zIndex: 10000 },
                                          }}
                                        >
                                          <IconButton size="small" sx={{ width: 40, height: 40 }}>
                                            <QrCodeIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </TableCell>
                                );
                              default:
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {quote[column.id as keyof Quote] || 'N/A'}
                                  </TableCell>
                                );
                            }
                          })}
                        </TableRow>
                      ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {/* Pagination Controls */}
          <TablePagination
            component="div"
            count={filteredQuotes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            sx={{
              mr: 2,
              // Target IconButton components within the actions section
              '& .MuiTablePagination-actions .MuiIconButton-root': {
                width: '32px', // Set desired width
                height: '32px', // Set desired height to maintain square buttons
                minWidth: '32px', // Override default minWidth to prevent buttons from stretching
              },
            }}
          />
        </TableContainer>
      </Box>

      {/* Confirmation Modal for Deletion */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="delete-confirmation-dialog-title"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            Are you sure you want to delete the selected quotes? You won't be able to reuse those quote generation
            credits.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setIsDeleteDialogOpen(false)} color="info">
            Cancel
          </Button>
          <Button variant='contained' onClick={handleConfirmDelete} color="error" disabled={isDeleting}>
            {isDeleting ? 'Deleting...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewAllQuotes;