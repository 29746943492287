import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  useTheme,
  Paper,
} from '@mui/material';
import {
  Description as PaperIcon,
  Circle as HoleIcon,
  Link as RubberBandIcon,
  ContentCut as CutterIcon,
  ChevronRight, WaterDrop
} from '@mui/icons-material';

const GetSupplies: React.FC = () => {
  const theme = useTheme();

  const supplies = [
    {
      title: "Door Hanger Paper",
      description: "Premium paper stock that'll weather any trail - perfect for your door hangers.",
      icon: <PaperIcon sx={{ fontSize: 40 }} />,
      link: "https://amzn.to/48xHLtE"
    },
    {
      title: "Heavy-Duty Hole Punchers",
      description: "Tough as a ranch hand, these punchers will keep your door hangers riding straight.",
      icon: <HoleIcon sx={{ fontSize: 40 }} />,
      link: "https://amzn.to/3BAKzKG"
    },
    {
      title: "Professional Rubber Bands",
      description: "Like a trusty lasso, these bands keep your door hangers secure and ready to ride.",
      icon: <RubberBandIcon sx={{ fontSize: 40 }} />,
      link: "https://amzn.to/4ehn3j5"
    },
    {
      title: "Paper Cutter",
      description: "Sharp as a ranch knife, this cutter makes quick work of any paper trail.",
      icon: <CutterIcon sx={{ fontSize: 40 }} />,
      link: "https://amzn.to/4hvU1Pu"
    },
    {
        title: "Printer Ink",
        description: "Keep your digital ranch running smooth with premium ink that'll make your QR codes shine like a Texas star.",
        icon: <WaterDrop sx={{ fontSize: 40 }} />, 
        link: "https://amzn.to/4fzrEhH"
      }
  ];

  return (
    <Box sx={{ p: 4, maxWidth: 'xl', mx: 'auto' }}>
      {/* Header Section */}
      <Paper elevation={0} sx={{ textAlign: 'center', mb: 6, p: 3, bgcolor: 'transparent' }}>
        <Typography 
          variant="h3" 
          gutterBottom
          sx={{ 
            color: 'warning.main',
            fontWeight: 'bold',
            mb: 3,
            mt:3,
          }}
        >
          Saddle Up With The Right Supplies
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            maxWidth: '950px', 
            mx: 'auto',
            color: 'text.secondary',
            lineHeight: 1.6
          }}
        >
          Here at CowboyQuote, we believe in putting you in the driver's seat of your marketing roundup. 
          No more waiting on slow-moving supply wagons or dealing with plain old paper that doesn't connect 
          to your digital corral. With our smart door hangers and the right supplies, you'll be running the 
          most efficient quote ranch in town. Each item below has been trail-tested and cowboy-approved to 
          work perfectly with your CowboyQuote system.
        </Typography>
      </Paper>

      {/* Supplies Grid */}
      <Grid container spacing={4}>
        {supplies.map((supply, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card 
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[8],
                  cursor: 'pointer',
                },
                position: 'relative',
                overflow: 'hidden',
                bgcolor: 'background.paper',
              }}
              onClick={() => window.open(supply.link, '_blank')}
            >
              <CardContent sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center',
                p: 3
              }}>
                <Box 
                  sx={{ 
                    color: 'warning.main',
                    mb: 2,
                    mt:2,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {supply.icon}
                </Box>
                <Typography 
                  variant="h6" 
                  gutterBottom 
                  sx={{ mb: 2 }}
                >
                  {supply.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ flexGrow: 1 }}
                >
                  {supply.description}
                </Typography>
                <IconButton 
                  size="small" 
                  sx={{ 
                    color: 'warning.main',
                    mt: 2,
                    width:40,
                    opacity: 0.5,
                    '&:hover': {
                      opacity: 1,
                    }
                  }}
                >
                  <ChevronRight />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Affiliate Disclosure */}
      <Typography 
        variant="caption" 
        display="block" 
        textAlign="center" 
        sx={{ 
          mt: 6,
          color: 'text.secondary'
        }}
      >
        Note: CowboyQuote may earn a commission from qualifying purchases.
      </Typography>
    </Box>
  );
};

export default GetSupplies;