// frontend/src/components/AdminDashboard/Home.tsx

import React, { useEffect, useState } from 'react';
import adminAxiosInstance from '../utils/adminAxiosConfig';
import {
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import CompanyTable from './CompanyTable';

// Define the Company interface
interface Company {
    _id: string;
    companyName: string;
    signUpDate: Date;
    planTier: string;
    totalQuotes: number;
    activeQuotes: number;
    acceptedQuotes: number;
    websiteLink: string;
    companyPhoneNumber: string;
  }

const Home: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

// In your useEffect in Home.tsx

useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await adminAxiosInstance.get('api/admin/dashboard-data');
  
        // Parse date fields
        const companiesWithParsedDates = response.data.companies.map((company: Company) => ({
          ...company,
          signUpDate: new Date(company.signUpDate),
        }));
  
        setCompanies(companiesWithParsedDates);
      } catch (err: any) {
        // Error handling...
      } finally {
        setLoading(false);
      }
    };
  
    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Companies Overview
      </Typography>
      <CompanyTable companies={companies} />
    </Box>
  );
};

export default Home;