import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Tooltip,
} from '@mui/material';

type RoleType = 'Owner' | 'Admin' | 'User';

interface RoleDescriptions {
  [key: string]: string;
}

interface RoleChangeModalProps {
  open: boolean;
  onClose: () => void;
  userName: string;
  currentRole: RoleType;
  onUpdateRole: (role: RoleType) => void;
}

const roleDescriptions: RoleDescriptions = {
  Owner: "Full control over company settings, templates, quotes, customers and users. Can manage roles and all aspects of the platform.",
  Admin: "Can manage all user quotes, templates, and customers. Cannot manage users.",
  User: "Can view templates and their own quotes. Full ability to activate quotes in the field via mobile app."
};

export const RoleChangeModal: React.FC<RoleChangeModalProps> = ({ 
  open, 
  onClose, 
  userName, 
  currentRole, 
  onUpdateRole 
}) => {
  const [selectedRole, setSelectedRole] = useState<RoleType>(currentRole);
  
  const hasChanges = useMemo(() => {
    return selectedRole !== currentRole;
  }, [selectedRole, currentRole]);

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value as RoleType);
  };

  const handleSubmit = () => {
    onUpdateRole(selectedRole);
    onClose();
  };

  const handleClose = () => {
    setSelectedRole(currentRole); // Reset selection on close
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Change Role for {userName}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          minHeight: '300px'
        }}>
          {/* Left side - Role selection */}
          <Box sx={{ 
            flex: '1 1 33%', 
            borderRight: '1px solid', 
            borderColor: 'divider',
            p: 2
          }}>
            <RadioGroup
              value={selectedRole}
              onChange={handleRoleChange}
            >
              {(Object.keys(roleDescriptions) as RoleType[]).map((role) => (
                <FormControlLabel
                  key={role}
                  value={role}
                  control={<Radio 
                    sx={{
                      '&.Mui-checked': {
                        color: 'warning.main',
                      },
                    }}
                  />}
                  label={role}
                  sx={{ mb: 2, color:'warning.main'}}
                />
              ))}
            </RadioGroup>
          </Box>

          {/* Right side - Role description */}
          <Box sx={{ flex: '1 1 67%', p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedRole} Permissions
            </Typography>
            <Typography variant="body1">
              {roleDescriptions[selectedRole]}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: 'text.primary',
            borderColor: 'text.primary',
            '&:hover': {
              borderColor: 'text.primary',
              backgroundColor: 'action.hover',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!hasChanges}
          sx={{
            backgroundColor: hasChanges ? 'warning.main' : 'action.disabledBackground',
            color: hasChanges ? 'warning.contrastText' : 'text.disabled',
            '&:hover': {
              backgroundColor: 'warning.dark',
            },
            '&.Mui-disabled': {
              backgroundColor: 'action.disabledBackground',
              color: 'text.disabled',
            },
          }}
        >
          Update User Role
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleChangeModal;