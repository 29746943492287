import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Paper,
  Chip,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axiosConfig';
import { QRCodeCanvas } from 'qrcode.react';
import CampaignMap, { CampaignCustomer } from './CampaignMap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

interface Campaign {
  campaignId: string;
  name: string;
  templateId: string;
  status: string;
  customers: Array<{
    name: string;
    address: {
      street: string;
      city: string;
      state: string;
      zip: string;
    };
    location: {
      type: string;
      coordinates: number[]; // [longitude, latitude]
    };
    color?: string;
  }>;
  createdAt: string;
}

const CampaignDetails: React.FC = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [templateName, setTemplateName] = useState<string>('Unknown Template');
  const [loading, setLoading] = useState(true);
  const [mapProvider, setMapProvider] = useState<'google' | 'apple'>('google');
  const [mapCenter, setMapCenter] = useState<[number, number]>([35.227085, -80.843124]);
  const [mapZoom, setMapZoom] = useState<number>(10);
  const mapRef = useRef<L.Map>(null);
  const [isCapturing, setIsCapturing] = useState<boolean>(false);

  useEffect(() => {
    const fetchCampaignDetail = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/campaigns/${campaignId}/detail`);
        const { campaign, templateName } = response.data;
        setCampaign(campaign);
        setTemplateName(templateName);
      } catch (error) {
        console.error('Error fetching campaign detail:', error);
        enqueueSnackbar('Failed to fetch campaign details', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (campaignId) {
      fetchCampaignDetail();
    }
  }, [campaignId, enqueueSnackbar]);

  useEffect(() => {
    if (campaign?.customers?.length) {
      const sorted = [...campaign.customers].sort(
        (a, b) => Number(b.address.zip) - Number(a.address.zip)
      );
      const [lon, lat] = sorted[0].location.coordinates;
      setMapCenter([lat, lon]);
      setMapZoom(10);
      if (mapRef.current) {
        mapRef.current.setView([lat, lon], 10);
      }
    }
  }, [campaign]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (!campaign) {
    return (
      <Box p={3}>
        <Typography variant="h6">Campaign not found.</Typography>
      </Box>
    );
  }

  // Sort customers by ZIP descending
  const sortedCustomers = [...campaign.customers].sort(
    (a, b) => Number(b.address.zip) - Number(a.address.zip)
  );

  // Format data for the map
  const customersForMap: CampaignCustomer[] = sortedCustomers.map((cust) => ({
    name: cust.name,
    address: cust.address,
    location: {
      type: 'Point',
      coordinates: [cust.location.coordinates[0], cust.location.coordinates[1]],
    },
    color: cust.color,
  }));

  // Clicking the color swatch locates on map
  const handleLocateCustomer = (customer: CampaignCustomer) => {
    const [lon, lat] = customer.location.coordinates;
    setMapCenter([lat, lon]);
    setMapZoom(14);
    if (mapRef.current) {
      mapRef.current.setView([lat, lon], 14);
    }
  };

  // Build directions link
  const getMapLink = (fullAddress: string) => {
    if (mapProvider === 'apple') {
      return `http://maps.apple.com/?daddr=${encodeURIComponent(fullAddress)}`;
    }
    // Using OpenStreetMap for directions
    return `https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=${encodeURIComponent(fullAddress)}`;
  };

  const createdDate = campaign.createdAt
    ? format(new Date(campaign.createdAt), 'PPP')
    : 'N/A';

  // ---------- PDF Export Logic ----------
  const handleExportPDF = async () => {
    try {
      // Set capturing state to expand the customer list and change layout
      setIsCapturing(true);
  
      // Allow time for the DOM to update (ensure list is fully expanded and layout is changed)
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Increased timeout for rendering
  
      // Temporarily expand the list height to show all items
      const listElement = document.getElementById('customerList');
      if (listElement) {
        listElement.style.height = 'auto'; // Expand height to show all items
      }
  
      // Capture the header
      const headerElement = document.getElementById('pdfHeader');
      if (headerElement) {
        headerElement.style.display = 'block'; // Show the header
      }
  
      const headerCanvas = await html2canvas(headerElement!, {
        useCORS: true, // attempt to load cross-origin images
        scale: 2, // increase resolution for better quality
        logging: true, // enable logging for debugging
      });
      const headerImgData = headerCanvas.toDataURL('image/png');
  
      // Hide the header again after capturing
      if (headerElement) {
        headerElement.style.display = 'none'; // Hide the header
      }
  
      // Capture the map
      const mapElement = document.getElementById('mapContainer');
      if (!mapElement) {
        enqueueSnackbar('Map area not found.', { variant: 'error' });
        return;
      }
  
      const mapCanvas = await html2canvas(mapElement, {
        useCORS: true, // attempt to load cross-origin images (map tiles, etc.)
        scale: 2, // increase resolution for better quality
        logging: true, // enable logging for debugging
      });
  
      // Crop the map canvas to remove 100px from the right side
      const cropWidth = mapCanvas.width - 1000; // Remove 100px from the right
      const cropHeight = mapCanvas.height;
  
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
  
      const ctx = croppedCanvas.getContext('2d');
      if (!ctx) {
        throw new Error('Could not create canvas context for cropping.');
      }
  
      // Draw the cropped portion of the map onto the new canvas
      ctx.drawImage(
        mapCanvas,
        0, // Source X (start from the left)
        0, // Source Y (start from the top)
        cropWidth, // Source width (crop width)
        cropHeight, // Source height (full height)
        0, // Destination X
        0, // Destination Y
        cropWidth, // Destination width
        cropHeight // Destination height
      );
  
      // Convert the cropped canvas to an image
      const croppedImgData = croppedCanvas.toDataURL('image/png');
  
      // Capture the customer list
      if (!listElement) {
        enqueueSnackbar('Customer list area not found.', { variant: 'error' });
        return;
      }
  
      const listCanvas = await html2canvas(listElement, {
        useCORS: true,
        scale: 2,
        logging: true, // enable logging for debugging
      });
      const listImgData = listCanvas.toDataURL('image/png');
  
      // Reset capturing state to restore original styles and layout
      setIsCapturing(false);
  
      // Restore the original list height
      if (listElement) {
        listElement.style.height = 'calc(75vh - 80px)'; // Restore original height
      }
  
      // Create a new PDF in portrait mode, letter size
      const pdf = new jsPDF('p', 'pt', 'letter');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
  
      // Add the header to the PDF
      const headerImgProps = pdf.getImageProperties(headerImgData);
      const headerPdfWidth = pageWidth - 40; // 20pt margin on each side
      const headerPdfHeight = (headerImgProps.height * headerPdfWidth) / headerImgProps.width;
  
      pdf.addImage(headerImgData, 'PNG', 20, 20, headerPdfWidth, headerPdfHeight);
  
      // Add the cropped map image to the PDF (stretched to fit the width)
      const mapImgProps = pdf.getImageProperties(croppedImgData);
      const mapPdfWidth = pageWidth - 40; // 20pt margin on each side
      const mapPdfHeight = (mapImgProps.height * mapPdfWidth) / mapImgProps.width;
  
      pdf.addImage(croppedImgData, 'PNG', 20, 20 + headerPdfHeight + 20, mapPdfWidth, mapPdfHeight);
  
      // Add the customer list below the map
      const listImgProps = pdf.getImageProperties(listImgData);
      const listPdfWidth = pageWidth - 40; // 20pt margin on each side
      const listPdfHeight = (listImgProps.height * listPdfWidth) / listImgProps.width;
  
      let remainingListHeight = listPdfHeight;
      let positionY = 20 + headerPdfHeight + 20 + mapPdfHeight + 20; // Start below the map
  
      // Add the first page of the list
      pdf.addImage(listImgData, 'PNG', 20, positionY, listPdfWidth, listPdfHeight);
      remainingListHeight -= (pageHeight - positionY - 20);
  
      // Handle multi-page if necessary
      while (remainingListHeight > 0) {
        pdf.addPage();
        positionY = 20;
        pdf.addImage(listImgData, 'PNG', 20, positionY, listPdfWidth, listPdfHeight);
        remainingListHeight -= (pageHeight - 40);
      }
  
      // Save the PDF
      pdf.save('campaign-details.pdf');
    } catch (err) {
      console.error('PDF Export Error:', err);
      enqueueSnackbar('Failed to export PDF', { variant: 'error' });
      setIsCapturing(false);
  
      // Restore the original list height in case of an error
      const listElement = document.getElementById('customerList');
      if (listElement) {
        listElement.style.height = 'calc(75vh - 80px)'; // Restore original height
      }
    }
  };

  return (
    <Box mt={8}>
      {/* Header Section */}
      <Card
        sx={{
          mx: 2,
          mb: 2,
          p: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Back Button */}
        <IconButton onClick={() => navigate(-1)} sx={{ width: 40 }}>
          <ArrowBack />
        </IconButton>

        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h5" gutterBottom>
            {campaign.name}
          </Typography>

          <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
            <Chip
              label={`Template: ${templateName}`}
              color="warning"
              variant="outlined"
              sx={{ fontWeight: 'bold' }}
            />
            <Chip
              label={`Created: ${createdDate}`}
              variant="outlined"
              sx={{ fontWeight: 'bold' }}
            />
            {campaign.status && (
              <Chip
                label={`Status: ${campaign.status.toUpperCase()}`}
                color={campaign.status === 'archived' ? 'default' : 'success'}
                variant="outlined"
                sx={{ fontWeight: 'bold' }}
              />
            )}
          </Box>
        </CardContent>



        {/* Map Provider selector */}
        
        <Box mr={2}>
          <TextField
            select
            size="small"
            value={mapProvider}
            onChange={(e) => setMapProvider(e.target.value as 'google' | 'apple')}
            label="Map Provider"
            color="warning"
            sx={{
              minWidth: 140,
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'warning.main',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'warning.main',
              },
            }}
          >
            <MenuItem value="google">Google Maps</MenuItem>
            <MenuItem value="apple">Apple Maps</MenuItem>
          </TextField>
        </Box>

        {/* PDF Download Button */}
        <Box mr={2}>
          <Button onClick={handleExportPDF} variant="contained" color="info">
            Download PDF
          </Button>
        </Box>

{/* View Campaign QR Code */}
<Box mr={2}>
  <IconButton 
    onClick={() => window.open(`${window.location.origin}/view-campaign/${campaignId}`, '_blank')}
    sx={{ p: 0 }} // Remove padding to make the QR code fit nicely
  >
    <QRCodeCanvas 
      value={`${window.location.origin}/view-campaign/${campaignId}`}
      size={80}
      bgColor="#ffffff"
      fgColor="#000000"
      level="H"
    />
  </IconButton>
  <Typography variant="caption" display="block" textAlign="center">
    Mobile View
  </Typography>
</Box>

      </Card>

      {/* The area we want to capture in the PDF */}
      <Box
        id="printableArea"
        sx={{
          display: 'flex',
          flexDirection: isCapturing ? 'column' : 'row',
          minHeight: '75vh',
          overflow: isCapturing ? 'hidden' : 'auto', // Allow scrolling in default render
        }}
      >
        {/* Hidden Header for PDF Export */}
        <Box
          id="pdfHeader"
          sx={{
            display: 'none', // Hide in the UI
            textAlign: 'center',
            p: 2,
            backgroundColor: '#f5f5f5', // Light gray background
            borderBottom: '1px solid #ddd', // Add a border for separation
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
            {campaign.name}
          </Typography>
          <Typography variant="h6" sx={{ color: '#666' }}>
            Template: {templateName}
          </Typography>
        </Box>

        {/* Map Section */}
        <Box
          id="mapContainer" // Add ID for map capture
          mb={isCapturing ? 4 : 0}
          width={isCapturing ? '100%' : { xs: '100%', md: '70%' }}
          sx={{
            p: 2,
          }}
        >
          <CampaignMap
            customers={customersForMap}
            center={mapCenter}
            zoom={mapZoom}
            style={{ height: '650px', width: '100%' }}
            ref={mapRef}
          />
        </Box>

        {/* Customer List Section */}
        <Box
  id="customerList" // Add ID for list capture
  width={isCapturing ? '100%' : { xs: '100%', md: '30%' }}
  sx={{
    p: 2,
    height: isCapturing ? 'auto' : 'calc(75vh - 80px)', // Set height to auto during PDF export
    overflowY: isCapturing ? 'visible' : 'auto', // Enable scroll in default render
  }}
>
  {/* PDF Export Layout (4 Columns) */}
  <Box
    sx={{
      columnCount: isCapturing ? 4 : 1, // Apply 4 columns only during PDF export
      columnGap: isCapturing ? '20px' : '0px', // Add gap only during PDF export
    }}
  >
    <List dense>
      {sortedCustomers.map((customer, index) => {
        const fullAddress = `${customer.address.street}, ${customer.address.city}, ${customer.address.state} ${customer.address.zip}`;
        const mapLink = getMapLink(fullAddress);

        return (
          <React.Fragment key={index}>
            <ListItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                pageBreakInside: 'avoid',
                height: isCapturing ? '120px' : 'auto', // Adjust height as needed
              }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: customer.color || '#666',
                  cursor: 'pointer',
                  mr: 2,
                }}
                onClick={() =>
                  handleLocateCustomer({
                    name: customer.name,
                    address: customer.address,
                    location: {
                      type: 'Point',
                      coordinates: [
                        customer.location.coordinates[0],
                        customer.location.coordinates[1],
                      ],
                    },
                    color: customer.color,
                  })
                }
                title="Locate on map"
              />
              <Box flexGrow={1}>
                <ListItemText
                  primary={customer.name}
                  secondary={fullAddress}
                />
              </Box>
              <Box ml={2}>
                <QRCodeCanvas
                  value={mapLink}
                  size={isCapturing ? 100 : 60} // Adjust size as needed
                />
              </Box>
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        );
      })}
    </List>
  </Box>
</Box>
      </Box>
    </Box>
  );
};

export default CampaignDetails;