import React, { useState } from 'react';
import { 
  Tooltip, 
  Typography,
  Box,
} from '@mui/material';
import RoleChangeModal from './RoleChangeModal';
import axiosInstance from '../utils/axiosConfig';
import { getDecodedToken } from '../utils/auth'; 

type RoleType = 'Owner' | 'Admin' | 'User';

interface User {
  userId: string;
  name: string;
  role: RoleType;
  [key: string]: any; // For other user properties
}

interface RoleCellProps {
  user: User;
  onRoleUpdate: (userId: string, newRole: RoleType) => void;
}

export const RoleCell: React.FC<RoleCellProps> = ({ user, onRoleUpdate }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const currentUser = getDecodedToken(); // Get current user info from token

  // Check if this is an owner trying to modify their own role
  const isCurrentOwner = currentUser?.userId === user.userId && user.role === 'Owner';
  const handleUpdateRole = async (newRole: RoleType) => {
    try {
      const response = await axiosInstance.put(`/api/users/${user.userId}/role`, {
        role: newRole
      });
      
      onRoleUpdate(user.userId, newRole);
    } catch (error) {
      console.error('Error updating user role:', error);
      throw error; // Let the parent component handle the error
    }
  };


// Determine tooltip message based on conditions
const getTooltipMessage = () => {
    if (isCurrentOwner) {
      return "You cannot change your own Owner role";
    }
    return "Click to change role";
  };


  return (
    <>
       <Tooltip title={getTooltipMessage()} arrow>
        <Box
          onClick={() => !isCurrentOwner && setModalOpen(true)}
          sx={{
            cursor: isCurrentOwner ? 'not-allowed' : 'pointer',
            '&:hover': {
              color: isCurrentOwner ? 'inherit' : 'warning.main',
            },
            transition: 'color 0.2s',
            opacity: isCurrentOwner ? 0.7 : 1,
          }}
        >
          <Typography>{user.role}</Typography>
        </Box>
      </Tooltip>

      {!isCurrentOwner && (
        <RoleChangeModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          userName={user.name}
          currentRole={user.role}
          onUpdateRole={handleUpdateRole}
        />
      )}
    </>
  );
};

export default RoleCell;