// frontend/src/components/AdminProtectedRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

interface AdminProtectedRouteProps {
  children: JSX.Element;
}

const AdminProtectedRoute: React.FC<AdminProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, isAdmin } = useAuth();

  console.log('AdminProtectedRoute: isAuthenticated:', isAuthenticated, 'isAdmin:', isAdmin);

  if (isAuthenticated === undefined || isAdmin === undefined) {
    // Authentication status is not yet determined, render a loader or null
    return null; // Or render a loading spinner
  }

  if (!isAuthenticated || !isAdmin) {
    console.log('AdminProtectedRoute: Access denied. Redirecting to /admin/login');
    return <Navigate to="/admin/login" replace />;
  }

  console.log('AdminProtectedRoute: Access granted to admin routes');
  return children;
};

export default AdminProtectedRoute;