import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Chip,
  Divider,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Add, Archive, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axiosConfig';
import CreateCampaignModal from './CreateCampaignModal';
import { useSnackbar } from 'notistack';

interface Campaign {
  campaignId: string;
  name: string;
  templateId: string;
  templateName?: string; // We'll populate this from /detail
  status: string;
  customers: Array<{
    name: string;
    address: {
      street: string;
      city: string;
      state: string;
      zip: string;
    };
  }>;
  createdAt: string;
}

const CampaignsList: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // 0 => Active, 1 => Archived
  const [deleteCampaignId, setDeleteCampaignId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchEnrichedCampaigns();
    // eslint-disable-next-line
  }, []);

  /**
   * Fetches the base list of campaigns, then for each campaign calls
   * /api/campaigns/:campaignId/detail to get the templateName.
   */
  const fetchEnrichedCampaigns = async () => {
    try {
      setLoading(true);
      // 1) Base campaigns
      const response = await axiosInstance.get('/api/campaigns');
      const baseCampaigns: Campaign[] = response.data;

      // 2) For each campaign, call /:campaignId/detail
      const enriched: Campaign[] = [];
      for (const c of baseCampaigns) {
        try {
          const detailRes = await axiosInstance.get(`/api/campaigns/${c.campaignId}/detail`);
          // detailRes.data => { campaign, templateName }
          enriched.push({
            ...c,
            templateName: detailRes.data.templateName || 'Unknown Template',
          });
        } catch (err) {
          console.error('Error fetching detail for campaign', c.campaignId, err);
          // fallback
          enriched.push({ ...c, templateName: 'Unknown Template' });
        }
      }

      setCampaigns(enriched);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      enqueueSnackbar('Failed to fetch campaigns', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Archives a campaign
   */
  const handleArchive = async (campaignId: string) => {
    try {
      await axiosInstance.patch(`/api/campaigns/${campaignId}/archive`);
      await fetchEnrichedCampaigns();
      enqueueSnackbar('Campaign archived successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error archiving campaign:', error);
      enqueueSnackbar('Failed to archive campaign', { variant: 'error' });
    }
  };

  /**
   * Deletes a campaign permanently
   */
  const handleDelete = async (campaignId: string) => {
    try {
      await axiosInstance.delete(`/api/campaigns/${campaignId}/delete`);
      await fetchEnrichedCampaigns();
      enqueueSnackbar('Campaign deleted permanently', { variant: 'success' });
    } catch (error) {
      console.error('Error deleting campaign:', error);
      enqueueSnackbar('Failed to delete campaign', { variant: 'error' });
    }
    setDeleteCampaignId(null);
  };

  /**
   * Activates an archived campaign (assuming you have a similar route)
   */
  const handleActivate = async (campaignId: string) => {
    try {
      await axiosInstance.patch(`/api/campaigns/${campaignId}/activate`);
      await fetchEnrichedCampaigns();
      enqueueSnackbar('Campaign activated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error activating campaign:', error);
      enqueueSnackbar('Failed to activate campaign', { variant: 'error' });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  // Separate campaigns into active vs. archived
  const activeCampaigns = campaigns.filter((c) => c.status !== 'archived');
  const archivedCampaigns = campaigns.filter((c) => c.status === 'archived');
  const displayCampaigns = activeTab === 0 ? activeCampaigns : archivedCampaigns;

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={4}>
        <Typography variant="h4">Campaigns</Typography>
        
        <Button
          variant="contained"
          color="warning"
          startIcon={<Add />}
          onClick={() => setModalOpen(true)}
          sx={{ width: 200 }}
        >
          Create Campaign
        </Button>
      </Box>
      <Typography variant="body1" sx={{ mb: 3 }}>
  Campaigns in CowboyQuote help you efficiently market to neighbors of your existing customers. By uploading a list of addresses where you've previously performed services, you can create targeted door hanger routes and easily navigate between locations. This feature streamlines your door-to-door marketing efforts by focusing on areas where you already have a proven track record, helping you build service density and grow your business strategically.
</Typography>
      {/* Tabs for Active / Archived */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'warning.main',
            },
          }}
          textColor="inherit"
        >
          <Tab
            label="Active"
            sx={(theme) => ({
              color: 'warning.main',
              '&.Mui-selected': {
                color: 'warning.main',
              },
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: alpha(theme.palette.warning.main, 0.8),
                cursor: 'pointer',
              },
            })}
          />
          <Tab
            label="Archived"
            sx={(theme) => ({
              color: 'warning.main',
              '&.Mui-selected': {
                color: 'warning.main',
              },
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: alpha(theme.palette.warning.main, 0.8),
                cursor: 'pointer',
              },
            })}
          />
        </Tabs>
      </Box>

      {displayCampaigns.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          {activeTab === 0
            ? 'No active campaigns. Create one to start targeting your customers’ neighborhoods!'
            : 'No archived campaigns.'}
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {displayCampaigns.map((campaign) => {
            const createdDate = format(new Date(campaign.createdAt), 'MMM d, yyyy');
            const isArchived = campaign.status === 'archived';
            const templateLabel = campaign.templateName || campaign.templateId;

            return (
              <Grid item xs={12} md={6} lg={4} key={campaign.campaignId}>
                <Card
                  // Entire card is clickable
                  onClick={() => navigate(`/dashboard/campaigns/${campaign.campaignId}`)}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    p: 2,
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: 4,
                    },
                  }}
                >
                  {/* Add Delete Button (only in archived tab) */}
                  {isArchived && activeTab === 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteCampaignId(campaign.campaignId);
                      }}
                      sx={{
                        width:40,
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'error.main',
                        '&:hover': {
                          backgroundColor: 'info',
                        }
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  )}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {campaign.name}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                      Template: <strong>{templateLabel}</strong>
                    </Typography>

                    <Divider sx={{ mb: 2 }} />

                    <Typography variant="body2" gutterBottom>
                      Created: {createdDate}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {campaign.customers.length} location(s)
                    </Typography>

                    <Chip
                      label={isArchived ? 'Archived' : 'Active'}
                      color={isArchived ? 'default' : 'success'}
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </CardContent>

                  {/* Button Row (Archive/Activate) - Stop Propagation */}
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    onClick={(e) => e.stopPropagation()} // prevents card click from firing
                  >
                    {/* If campaign is active => show Archive button */}
                    {!isArchived && (
                      <Tooltip title="Archive Campaign">
                        <IconButton
                          onClick={() => handleArchive(campaign.campaignId)}
                          color="warning"
                          sx={{ mr: 1, width: 30 }}
                        >
                          <Archive />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* If campaign is archived => show Activate button */}
                    {isArchived && (
                      <Tooltip title="Activate Campaign">
                        <Button
                          variant="outlined"
                          color="warning"
                          sx={{ mr: 1 }}
                          onClick={() => handleActivate(campaign.campaignId)}
                        >
                          Activate
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}

  {/* Delete Confirmation Dialog */}
  <Dialog
        open={Boolean(deleteCampaignId)}
        onClose={() => setDeleteCampaignId(null)}
      >
        <DialogTitle>Permanently delete campaign?</DialogTitle>
        <DialogContent>
          This action cannot be undone. All associated data will be removed.
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='info' onClick={() => setDeleteCampaignId(null)}>Cancel</Button>
          <Button 
            onClick={() => deleteCampaignId && handleDelete(deleteCampaignId)}
            color="error"
            variant="contained"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Campaign Modal */}
      <CreateCampaignModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSuccess={() => {
          setModalOpen(false);
          fetchEnrichedCampaigns();
        }}
      />
    </Box>
  );
};

export default CampaignsList;