// src/components/campaigns/CampaignMap.tsx

import React, { useEffect, forwardRef } from 'react';
import L, { Map as LeafletMap } from 'leaflet';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from 'react-leaflet';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import 'leaflet/dist/leaflet.css';

// Tile styles for theme switching
const mapStyles = {
  light: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
};

// Subcomponent to dynamically update the map center/zoom
const MapUpdater = ({
  center,
  zoom,
}: {
  center: L.LatLngExpression;
  zoom: number;
}) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [map, center, zoom]);
  return null;
};

// Interface for a single campaign customer
export interface CampaignCustomer {
  name: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  location: {
    type: 'Point';
    coordinates: [number, number];
  };
  color?: string;
};

// Create a custom SVG-based marker icon
const getCustomIcon = (color: string = '#666') => {
  // Define the SVG as a string
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 384 512">
      <!-- Base marker path -->
      <path fill="${color}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 
        0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 
        309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
      <!-- White circle background for icon -->
      <circle cx="192" cy="192" r="96" fill="white" opacity="0.9"/>
    </svg>
  `;
  const encodedSVG = encodeURIComponent(svg);
  const iconUrl = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;

  return new L.Icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
    className: '', // Remove default styles
  });
};

// Props for CampaignMap
export interface CampaignMapProps {
  customers: CampaignCustomer[];
  center: [number, number];
  zoom: number;
  style?: React.CSSProperties;
}

// Forward the ref to access the Leaflet map instance
const CampaignMap = forwardRef<LeafletMap, CampaignMapProps>(({
  customers,
  center,
  zoom,
  style = { height: '400px', width: '100%' },
}, ref) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <MapContainer center={center} zoom={zoom} style={style} ref={ref}>
      <MapUpdater center={center} zoom={zoom} />

      <TileLayer
        url={isDarkMode ? mapStyles.dark : mapStyles.light}
        attribution={
          isDarkMode
            ? '&copy; <a href="https://carto.com/attributions">CARTO</a>'
            : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }
        crossOrigin="anonymous" // Ensure CORS is enabled
      />

      {customers.map((customer, idx) => {
        const [lon, lat] = customer.location.coordinates;
        const position: [number, number] = [lat, lon];
        const fullAddress = `${customer.address.street}, ${customer.address.city}, ${customer.address.state} ${customer.address.zip}`;
        const markerColor = customer.color || '#666'; // fallback color if none

        return (
          <Marker
            key={idx}
            position={position}
            icon={getCustomIcon(markerColor)}
          >
            <Popup>
              <Box
                sx={{
                  color: isDarkMode ? '#FFF' : '#000',
                  backgroundColor: isDarkMode ? '#1E1F20' : '#FFF',
                  p: 1,
                  borderRadius: 1,
                  maxWidth: 200,
                }}
              >
                <Typography variant="subtitle2">{customer.name}</Typography>
                <Typography variant="body2">{fullAddress}</Typography>
              </Box>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
});

export default CampaignMap;