// frontend/src/components/NotFound.tsx

import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: 2,
      }}
    >
      <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 80, mb: 2 }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you're looking for doesn't exist or has been moved.
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" onClick={handleGoHome}>
          Go to Home
        </Button>
        {/* Optional: Add more navigation buttons if needed */}
        {/* <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
          Go Back
        </Button> */}
      </Stack>
    </Box>
  );
};

export default NotFound;