// src/components/TrailGuide/GuideList.tsx

import React from 'react';
import { List, Typography } from '@mui/material';
import GuideItemComponent from './GuideItem';
import { styled } from '@mui/system';

interface GuideItem {
  id: string;
  title: string;
  category: string;
  content: string;
  isPinned?: boolean;
  link?: string;
  featured_media_url?: string;
}

interface GuideListProps {
  guides: GuideItem[];
  expandedItem: string | null;
  handleItemClick: (id: string) => void;
  isSearchResults: boolean; // New prop to indicate if this is a search result
}

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
  padding: theme.spacing(0),
}));

const GuideList: React.FC<GuideListProps> = ({ guides, expandedItem, handleItemClick, isSearchResults }) => {
  if (guides.length === 0) {
    return (
      <Typography variant="body1" align="center" color="textSecondary" sx={{ mt: 4 }}>
        No guides found matching your search.
      </Typography>
    );
  }

  return (
    <StyledList>
      {guides.map((item) => (
        <GuideItemComponent
          key={item.id}
          item={item}
          isExpanded={expandedItem === item.id}
          onClick={handleItemClick}
          isSearchResult={isSearchResults} // Pass the prop down
        />
      ))}
    </StyledList>
  );
};

export default GuideList;