// frontend/src/components/AdminDashboard/CompanyTable.tsx

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIcon from '@mui/icons-material/Phone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

type Order = 'asc' | 'desc';

interface Company {
  _id: string;
  companyName: string;
  signUpDate: Date;
  planTier: string;
  totalQuotes: number;
  activeQuotes: number;
  acceptedQuotes: number;
  websiteLink: string;
  companyPhoneNumber: string;
}

interface CompanyTableProps {
  companies: Company[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  // Handle dates
  if (aValue instanceof Date && bValue instanceof Date) {
    return bValue.getTime() - aValue.getTime();
  }

  // Handle numbers
  if (typeof bValue === 'number' && typeof aValue === 'number') {
    return bValue - aValue;
  }

  // Handle strings
  if (typeof bValue === 'string' && typeof aValue === 'string') {
    return bValue.localeCompare(aValue);
  }

  return 0;
}

function getComparator<Key extends keyof Company>(
  order: Order,
  orderBy: Key
): (a: Company, b: Company) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const CompanyTable: React.FC<CompanyTableProps> = ({ companies }) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Company>('companyName');
  const [copiedPhoneId, setCopiedPhoneId] = useState<string | null>(null);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Company
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedCompanies = companies.slice().sort(getComparator(order, orderBy));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'companyName'}
                direction={orderBy === 'companyName' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'companyName')}
              >
                <strong>Company Name</strong>
                {orderBy === 'companyName' ? (
                  <span style={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            {/* Empty TableCells for alignment */}
            <TableCell align="center" style={{ width: '50px' }}></TableCell>
            <TableCell align="center" style={{ width: '50px' }}></TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={orderBy === 'signUpDate'}
                direction={orderBy === 'signUpDate' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'signUpDate')}
              >
                <strong>Sign-Up Date</strong>
                {orderBy === 'signUpDate' ? (
                  <span style={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={orderBy === 'planTier'}
                direction={orderBy === 'planTier' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'planTier')}
              >
                <strong>Plan Tier</strong>
                {orderBy === 'planTier' ? (
                  <span style={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={orderBy === 'totalQuotes'}
                direction={orderBy === 'totalQuotes' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'totalQuotes')}
              >
                <strong>Total (All Time)</strong>
                {orderBy === 'totalQuotes' ? (
                  <span style={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={orderBy === 'activeQuotes'}
                direction={orderBy === 'activeQuotes' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'activeQuotes')}
              >
                <strong>Active (This Month)</strong>
                {orderBy === 'activeQuotes' ? (
                  <span style={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={orderBy === 'acceptedQuotes'}
                direction={orderBy === 'acceptedQuotes' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'acceptedQuotes')}
              >
                <strong>Accepted (This Month)</strong>
                {orderBy === 'acceptedQuotes' ? (
                  <span style={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedCompanies.map((company) => (
            <TableRow key={company._id}>
             <TableCell>
                    <Link
                        to={`viewcompany/${company._id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        {company.companyName}
                    </Link>
                    </TableCell>
              <TableCell align="center" style={{ width: '50px' }}>
                {company.websiteLink ? (
                  <IconButton
                    color="warning"
                    onClick={() =>
                      window.open(company.websiteLink, '_blank', 'noopener,noreferrer')
                    }
                  >
                    <LanguageIcon />
                  </IconButton>
                ) : (
                  <LanguageIcon color="disabled" />
                )}
              </TableCell>
              <TableCell align="center" style={{ width: '50px' }}>
                {company.companyPhoneNumber ? (
                  <CopyToClipboard
                    text={company.companyPhoneNumber}
                    onCopy={() => {
                      setCopiedPhoneId(company._id);
                      setTimeout(() => setCopiedPhoneId(null), 2000);
                    }}
                  >
                    <Tooltip
                      title={
                        copiedPhoneId === company._id
                          ? 'Copied!'
                          : company.companyPhoneNumber
                      }
                    >
                      <IconButton color="warning">
                        <PhoneIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                ) : (
                  <PhoneIcon color="disabled" />
                )}
              </TableCell>
              <TableCell align="center">
                {company.signUpDate.toLocaleDateString()}
              </TableCell>
              <TableCell align="center">{company.planTier}</TableCell>
              <TableCell align="center">{company.totalQuotes}</TableCell>
              <TableCell align="center">{company.activeQuotes}</TableCell>
              <TableCell align="center">{company.acceptedQuotes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanyTable;