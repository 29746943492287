// src/hooks/useFetchWordPressArticles.ts

import { useState, useEffect } from 'react';
import { fetchWordPressPosts } from '../utils/wordpressApi';
import { decodeHtml } from '../utils/decodeHtml'; // Import the decode function

interface WordPressArticle {
  id: number;
  title: string;
  excerpt: string;
  link: string;
  featured_media_url: string;
  date: string;
}

const useFetchWordPressArticles = () => {
  const [wordpressArticles, setWordpressArticles] = useState<WordPressArticle[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getArticles = async () => {
    setLoading(true);
    try {
      const articles = await fetchWordPressPosts();
      const articlesWithImages = articles.map((article: any) => {
        const featuredMedia = article._embedded?.['wp:featuredmedia']?.[0];
        return {
          id: article.id,
          title: decodeHtml(article.title.rendered), // Decode HTML entities
          excerpt: decodeHtml(article.excerpt.rendered.replace(/<[^>]+>/g, '')), // Remove HTML tags and decode
          link: article.link,
          featured_media_url: featuredMedia ? featuredMedia.source_url : '',
          date: article.date,
        };
      });
      setWordpressArticles(articlesWithImages);
    } catch (err) {
      console.error('Failed to fetch articles:', err);
      setError('Failed to fetch articles');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticles();

    // Polling every 10 minutes
    const interval = setInterval(() => {
      getArticles();
    }, 600000); // 600,000 ms = 10 minutes

    return () => clearInterval(interval);
  }, []);

  return { wordpressArticles, loading, error };
};

export default useFetchWordPressArticles;