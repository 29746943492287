// frontend/src/utils/adminAxiosConfig.ts

import axios from 'axios';

// Base URL should point to your backend API
const adminAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000', // Ensure this points to your backend
    headers: {
        'Content-Type': 'application/json',
    }
});

// Request interceptor to include admin token
adminAxiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('adminToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle unauthorized responses
adminAxiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
            // Clear admin tokens
            localStorage.removeItem('adminToken');
            localStorage.removeItem('adminData');
            // Redirect to admin login
            window.location.href = '/admin/login';
        }
        return Promise.reject(error);
    }
);

export default adminAxiosInstance;