// frontend/src/components/AdminLogin.tsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import adminAxiosInstance from '../utils/adminAxiosConfig';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import CowboyHatLogo from './CowboyHatLogo';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setIsAdmin } = useAuth(); // Add setIsAdmin

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('AdminLogin: Form submitted');
    setError('');
    setLoading(true);

    try {
      console.log('AdminLogin: Sending login request with email:', email);
      // Updated endpoint to match our new structure
      const response = await adminAxiosInstance.post('/api/admin/auth/login', {
        email,
        password,
      });

      console.log('AdminLogin: Received response:', response.data);

      // Store the admin token in localStorage with a different key than user token
      localStorage.setItem('adminToken', response.data.token);
      console.log('AdminLogin: Stored adminToken in localStorage');

      // Store admin data with more specific information
      const adminData = {
        id: response.data.id,
        email: response.data.email,
        role: response.data.role,
        permissions: response.data.permissions,
        isAdmin: true, // Add explicit admin flag
      };
      localStorage.setItem('adminData', JSON.stringify(adminData));
      console.log('AdminLogin: Stored adminData in localStorage:', adminData);

      // Update auth context to reflect admin status
      setIsAuthenticated(true);
      console.log('AdminLogin: setIsAuthenticated(true)');
      setIsAdmin(true); // Set admin status in context
      console.log('AdminLogin: setIsAdmin(true)');

      // Redirect to admin dashboard
      console.log('AdminLogin: Navigating to /admin/dashboard');
      navigate('/admin/dashboard');
    } catch (err: any) {
      console.error('AdminLogin: Admin login error:', err);

      if (err.response) {
        console.log('AdminLogin: Error response status:', err.response.status);
      }

      if (err.response?.status === 401) {
        setError('Invalid admin credentials.');
      } else if (err.response?.status === 429) {
        setError('Too many login attempts. Please try again later.');
      } else {
        setError('Login failed. Please check your credentials or try again later.');
      }
    } finally {
      setLoading(false);
      console.log('AdminLogin: Loading set to false');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: 'background.default',
        p: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CowboyHatLogo />

        <Typography variant="h5" component="h1" gutterBottom sx={{ mb: 3 }}>
          Admin Login
        </Typography>

        {error && (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            fullWidth
            label="Admin Email"
            value={email}
            onChange={(e) => {
              console.log('AdminLogin: Email changed to:', e.target.value);
              setEmail(e.target.value);
            }}
            margin="normal"
            required
            type="email"
            autoComplete="email"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'warning.main',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'warning.main',
              },
            }}
          />

          <TextField
            fullWidth
            label="Admin Password"
            value={password}
            onChange={(e) => {
              console.log('AdminLogin: Password changed');
              setPassword(e.target.value);
            }}
            margin="normal"
            required
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      console.log('AdminLogin: Toggling password visibility');
                      setShowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'warning.main',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'warning.main',
              },
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="warning"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? 'Logging in...' : 'Admin Login'}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default AdminLogin;