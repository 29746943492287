import React, { useState, useEffect, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

interface NavStylesType {
  nav: CSSProperties;
  container: CSSProperties;
  wrapper: CSSProperties;
  logoContainer: CSSProperties;
  logo: CSSProperties;
  desktopMenu: CSSProperties;
  navLink: CSSProperties;
  loginButton: CSSProperties;
  hamburger: CSSProperties;
  hamburgerLine: CSSProperties;
  mobileMenu: CSSProperties;
  mobileLink: CSSProperties;
}

const NavBar: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  const navStyles: NavStylesType = {
    nav: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 50,
      transition: 'all 0.3s',
      backgroundColor: scrolled ? 'rgba(5, 47, 70, 0.9)' : '#052F46', // Changed this line
      backdropFilter: scrolled ? 'blur(8px)' : 'none',
      boxShadow: scrolled ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
     
    },
    container: {
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '0 1rem',
      
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '80px',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      textDecoration: 'none',
    },
    logo: {
      
      width: '200px',
      transition: 'transform 0.3s',
    },
   
    desktopMenu: {
      display: isMobile ? 'none' : 'flex',
      alignItems: 'center',
      gap: '2rem',
    },
    navLink: {
      color: scrolled ? 'white' : 'white', 
      textDecoration: 'none',
      transition: 'color 0.3s',
    },
    loginButton: {
      padding: '0.5rem 1rem',
      borderRadius: '0.375rem',
      backgroundColor: scrolled ? '#C6623A' : '#C6623A', // Changed this line
      color: 'white',
      textDecoration: 'none',
      transition: 'backgroundColor 0.3s',
    },
    hamburgerLine: {
      width: '100%',
      height: '2px',
      backgroundColor: scrolled ? 'white' : 'white', // Changed this line
      transition: 'all 0.3s',
    },
    hamburger: {
      padding: '0.5rem',
      display: isMobile ? 'flex' : 'none',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '24px',
      height: '20px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
   
    mobileMenu: {
      padding: '1rem',
      backgroundColor: '#00647A',
      borderTop: '1px solid #e5e5e5',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', // Proper shadow syntax
      textAlign: 'right', // Align text to the right
    },
    mobileLink: {
      display: 'block',
      padding: '0.75rem',
      marginBottom: '0.5rem',
      borderRadius: '0.375rem',
      color: 'white',
      textDecoration: 'none',
      transition: 'backgroundColor 0.3s',
      textAlign: 'center', // Ensure the link text itself is right-aligned
    },
  };

  return (
    <nav style={navStyles.nav}>
      <div style={navStyles.container}>
        <div style={navStyles.wrapper}>
          <Link to="/" style={navStyles.logoContainer}>
            <img 
              src="/logo-text-white.webp" 
              alt="CowboyQuote Logo" 
              style={navStyles.logo}
            />
           
          </Link>

          <div style={navStyles.desktopMenu}>
            <Link to="/" style={navStyles.navLink}>Home</Link>
            {!isAuthenticated ? (
              <>
                <Link to="/signup" style={navStyles.navLink}>Sign Up</Link>
                <Link to="/login" style={navStyles.loginButton}>Login</Link>
              </>
            ) : (
              <>
                <Link to="/dashboard" style={navStyles.navLink}>Dashboard</Link>
                <Link to="/logout" style={navStyles.navLink}>Logout</Link>
              </>
            )}
          </div>

          <button
            onClick={() => setIsOpen(!isOpen)}
            style={navStyles.hamburger}
          >
            <span style={{
              ...navStyles.hamburgerLine,
              transform: isOpen ? 'rotate(45deg) translateY(6px)' : 'none',
            }} />
            <span style={{
              ...navStyles.hamburgerLine,
              opacity: isOpen ? 0 : 1,
            }} />
            <span style={{
              ...navStyles.hamburgerLine,
              transform: isOpen ? 'rotate(-45deg) translateY(-6px)' : 'none',
            }} />
          </button>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={navStyles.mobileMenu}
          >
            <Link to="/" style={navStyles.mobileLink} onClick={() => setIsOpen(false)}>
              Home
            </Link>
            {!isAuthenticated ? (
              <>
                <Link to="/signup" style={navStyles.mobileLink} onClick={() => setIsOpen(false)}>
                  Sign Up
                </Link>
                <Link to="/login" style={{
                  ...navStyles.mobileLink,
                  ...navStyles.loginButton,
                  textAlign: 'center',
                }} onClick={() => setIsOpen(false)}>
                  Login
                </Link>
              </>
            ) : (
              <>
                <Link to="/dashboard" style={navStyles.mobileLink} onClick={() => setIsOpen(false)}>
                  Dashboard
                </Link>
                <Link to="/logout" style={navStyles.mobileLink} onClick={() => setIsOpen(false)}>
                  Logout
                </Link>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default NavBar;