//components/QuoteMap.tsx
import L from 'leaflet';
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngExpression, Icon } from 'leaflet';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import UserChip from './UserChip';
import 'leaflet/dist/leaflet.css';

// Import marker icons
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Define the icon for markers
const defaultIcon = new Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

interface Quote {
  quoteId: string;
  totalValue: number;
  location?: {
    latitude: number;
    longitude: number;
  };
  status: 'Active' | 'Accepted';
  templateName?: string;
  customerName?: string;
  activatedTime?: string;
  acceptedTime?: string;
  activatedByUserId: string;
  activatedByUserName: string;
}

interface QuoteMapProps {
  quotes: Quote[];
  companyLocation?: {
    latitude: number;
    longitude: number;
  };
  userColors?: { [key: string]: string };
}

const mapStyles = {
  light: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
};

// Enhanced MapUpdater component that handles dynamic location updates
const MapUpdater = ({ center, zoom }: { center: LatLngExpression, zoom: number }) => {
  const map = useMap();
  
  useEffect(() => {
    map.setView(center, zoom);
  }, [map, center, zoom]);

  return null;
};

const getCustomIcon = (quote: Quote, userColors: { [key: string]: string }) => {
  // Default to '#666' if no userColor is found
  const userColor = userColors?.[quote.activatedByUserId] || '#666';

  const markerSvg = encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <!-- Base marker path -->
      <path fill="${userColor}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
      <!-- White circle background for icon -->
      <circle cx="192" cy="192" r="96" fill="white" opacity="0.9"/>
    </svg>
  `);

  const iconUrl = `data:image/svg+xml;charset=utf-8,${markerSvg}`;

  return new L.Icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
  });
};

const QuoteMap: React.FC<QuoteMapProps> = ({ quotes, companyLocation, userColors = {} }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const zoom = 10;

  // Set center based on companyLocation or default to Charlotte, NC
  const center: LatLngExpression = companyLocation 
    ? [companyLocation.latitude, companyLocation.longitude]
    : [35.227085, -80.843124];

  // Custom Popup styles based on theme
  const popupStyle: React.CSSProperties = {
    color: isDarkMode ? '#FFFFFF' : '#000000',
    backgroundColor: isDarkMode ? '#333333' : '#FFFFFF',
    padding: '8px',
    borderRadius: '4px',
  };

  return (
    <MapContainer 
      center={center}
      zoom={zoom} 
      style={{ height: '400px', width: '100%' }}
    >
      <MapUpdater center={center} zoom={zoom} />
      <TileLayer
        url={isDarkMode ? mapStyles.dark : mapStyles.light}
        attribution={
          isDarkMode
            ? '&copy; <a href="https://carto.com/attributions">CARTO</a> | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>'
            : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
      />
      {quotes.map((quote) => {
        if (quote.location && quote.location.latitude && quote.location.longitude) {
          return (
            <Marker 
              key={quote.quoteId} 
              position={[quote.location.latitude, quote.location.longitude]}
              icon={getCustomIcon(quote, userColors)}
            >
              <Popup>
                <Box sx={{ 
                  color: isDarkMode ? '#FFFFFF' : '#000000',
                  backgroundColor: isDarkMode ? '#1E1F20' : '#FFFFFF',
                  p: 1,
                  borderRadius: 1
                }}>
                  <Typography variant="subtitle2">{quote.customerName}</Typography>
                  <Typography variant="body2">{quote.templateName}</Typography>
                  <Typography variant="body2">${quote.totalValue.toFixed(2)}</Typography>
                  <UserChip
                    userId={quote.activatedByUserId}
                    userName={quote.activatedByUserName}
                    userColor={userColors[quote.activatedByUserId] || '#666'}
                    size="small"
                  />
                </Box>
              </Popup>
            </Marker>
          );
        }
        return null;
      })}
    </MapContainer>
  );
};

export default QuoteMap;