// src/components/TrailGuide/GuideItem.tsx

import React from 'react';
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Typography,
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import {
  VideoLibrary,
  QuestionAnswer,
  Article as ArticleIcon,
  Star,
  ExpandLess,
  ExpandMore,
  LibraryBooks,
} from '@mui/icons-material';

interface GuideItemProps {
  item: GuideItem;
  isExpanded: boolean;
  onClick: (id: string) => void;
  isSearchResult: boolean; // Indicates if this is part of search results
}

interface GuideItem {
  id: string;
  title: string;
  category: string;
  content: string;
  isPinned?: boolean;
  link?: string;
  featured_media_url?: string;
}

const GuideItemComponent: React.FC<GuideItemProps> = ({ item, isExpanded, onClick, isSearchResult }) => {
  const getCategoryIcon = (category: string) => {
    switch (category) {
      case 'how-to':
        return <VideoLibrary color="primary" />;
      case 'faq':
        return <QuestionAnswer color="info" />;
      case 'announcements':
        return <ArticleIcon color="warning" />;
      case 'articles':
        return <LibraryBooks color="secondary" />;
      default:
        return <Star color="disabled" />;
    }
  };

  // Function to capitalize and format the category name
  const formatCategory = (category: string) => {
    switch (category) {
      case 'how-to':
        return 'How-To';
      case 'faq':
        return 'FAQs';
      case 'announcements':
        return 'Announcements';
      case 'articles':
        return 'Articles';
      default:
        return category.charAt(0).toUpperCase() + category.slice(1);
    }
  };

  return (
    <Card
      sx={{
        mb: 2,
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <Tooltip title={formatCategory(item.category)} placement="top" arrow>
        <ListItem button onClick={() => onClick(item.id)}>
          <ListItemIcon>{getCategoryIcon(item.category)}</ListItemIcon>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="subtitle1">{item.title}</Typography>
                {item.isPinned && <Star fontSize="small" color="warning" />}
              </Box>
            }
          />
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body1" color="text.secondary">
            {item.content}
          </Typography>
          {/* Conditionally render "Read More" button for articles in search results */}
          {isSearchResult && item.category === 'articles' && item.link && (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="warning"
                href={item.link}
                target="_blank"
                rel="noopener"
                aria-label={`Read more about ${item.title}`}
              >
                Read More
              </Button>
            </Box>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default GuideItemComponent;