// src/components/QuoteTemplatePage.tsx

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Paper,
  Grid,
  IconButton,
  Tooltip,
  Chip,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Alert,
} from '@mui/material';
import { Download, Email, ArrowBack } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import CowboyHatLogo from './CowboyHatLogo';
import Footer from './Footer';
import { getDecodedToken } from '../utils/auth';
import { lighten } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { styled } from '@mui/material/styles';

// **Define Interfaces Directly in the File**

interface Service {
  name: string;
  description: string;
  quotePageDesc?: string;
  fineText?: string;
  displayName?: string;
}

interface Quote {
  quoteId: string;
  templateId: string;
  templateName: string;
  customerName: string;
  services: string[]; // Array of service names
  serviceValues: string[];
  totalValue: number;
  status: 'Active' | 'Accepted';
  activatedTime: string;
  acceptedTime?: string;
  expirationTime: string;
  videoPath?: string;
  userId: string;
}

interface QuoteTemplate {
  name: string;
  introParagraph: string;
  customServices: Service[];
  predefinedServiceIds: string[]; // Array of PredefinedService IDs
  companyName: string;
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
    showTitle: boolean;
  };
  landingPageIntro?: string;
  daysToExpire: number;
}

interface CustomerData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature?: string;
}

interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string | null;
  planTier: string;
  whoWeAreText?: string;
  googleMapEmbed?: string;
  websiteLink?: string;
}

interface User {
  whoIAmText?: string;
}

// Styled component for service description
const ServiceDescription = styled(Box)(({ theme }) => ({
  color: '#1E1F20',
  fontSize: '0.75rem', // 14px, reduced by approximately 2 points
  '& p': { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  '& ul, & ol': { marginTop: theme.spacing(1), marginBottom: theme.spacing(1), paddingLeft: theme.spacing(3) },
  '& li': { marginBottom: theme.spacing(0.5) },
  '& strong': { fontWeight: 'bold' },
  '& em': { fontStyle: 'italic' },
  wordBreak: 'break-word',
}));

// **QuoteTemplatePage Component**
const QuoteTemplatePage: React.FC = () => {
  const decodedToken = getDecodedToken();
  const userId = decodedToken?.userId || '';

  const { templateId } = useParams<{ templateId: string }>(); // Extract templateId from URL

  const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null);
  const [userData, setUserData] = useState<User | null>(null);

  const [quote, setQuote] = useState<Quote | null>(null);
  const [template, setTemplate] = useState<QuoteTemplate | null>(null);
  const [predefinedServices, setPredefinedServices] = useState<Service[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // **Error and Loading States**
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // **Fetch Company Settings and User Data**
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyRes, userRes] = await Promise.all([
          axiosInstance.get('/api/compSettings'),
          axiosInstance.get(`/api/users/${userId}`),
        ]);

        setCompanySettings(companyRes.data);
        setUserData({
          whoIAmText: userRes.data.whoIAmText ?? '',
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    } else {
      setError('User is not authenticated.');
      setLoading(false);
    }
  }, [userId]);

  const handleBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  // **Fetch Quote Template and Predefined Services**
  useEffect(() => {
    const fetchQuoteTemplate = async () => {
      if (!templateId) {
        setError('Template ID is missing from the URL.');
        return;
      }

      try {
        // Fetch the quote template using the templateId
        const templateRes = await axiosInstance.get(`/api/quoteTemplates/${templateId}`);
        const templateData: QuoteTemplate = templateRes.data;

        if (!templateData) {
          throw new Error('Template data is undefined');
        }

        setTemplate(templateData);

        // **Fetch Predefined Services Based on templateData.predefinedServiceIds**
        if (
          Array.isArray(templateData.predefinedServiceIds) &&
          templateData.predefinedServiceIds.length > 0
        ) {
          const companyName = templateData.companyName;

          // Convert predefinedServiceIds array to a comma-separated string
          const serviceIds = templateData.predefinedServiceIds.join(',');

          // Make a GET request to the new authenticated route with filter
          const predefinedRes = await axiosInstance.get(
            `/api/predefinedServices/filter`,
            {
              params: { ids: serviceIds },
            }
          );

          setPredefinedServices(predefinedRes.data);
        }
      } catch (error) {
        console.error('Error fetching quote template or predefined services:', error);
        setError('Failed to load quote template or predefined services. Please try again.');
      }
    };

    if (companySettings && templateId) {
      fetchQuoteTemplate();
    }
  }, [companySettings, templateId]);

  // **Mock Data for Customer Model**
  const mockCustomerData: CustomerData = {
    firstName: 'John',
    lastName: 'Smith',
    address: '123 Main St',
    city: 'Charlotte',
    state: 'NC',
    zip: '28202',
    email: 'john.smith@example.com',
    phone: '555-123-4567',
    signature: undefined,
  };

  // **Initialize Quote with Both Custom and Predefined Services**
  useEffect(() => {
    if (template) {
      // Extract service names from customServices
      const customServiceNames = template.customServices.map((service) => service.name);

      // Extract service names from predefinedServices
      const predefinedServiceNames = predefinedServices.map((service) => service.name);

      // Combine both service arrays
      const combinedServices = [...customServiceNames, ...predefinedServiceNames];

      // Generate mock service values (ensure this matches the number of combined services)
      const mockServiceValues = combinedServices.map(() => {
        // For demonstration, assigning random values. Replace with actual logic as needed.
        return (Math.random() * 100 + 100).toFixed(2);
      });

      // Calculate total value
      const total = mockServiceValues.reduce((acc, val) => acc + parseFloat(val), 0);

      const mockQuote: Quote = {
        quoteId: 'Q123456',
        templateId: templateId || 'T78910',
        templateName: template.name,
        customerName: `${mockCustomerData.firstName} ${mockCustomerData.lastName}`,
        services: combinedServices,
        serviceValues: mockServiceValues,
        totalValue: total,
        status: 'Active', // Change to 'Accepted' to simulate acceptance
        activatedTime: '2024-04-01T10:00:00Z',
        acceptedTime: undefined, // Undefined until accepted
        expirationTime: '2024-05-01T10:00:00Z',
        videoPath: '/videos/sample.mp4',
        userId: 'U987654',
      };
      setQuote(mockQuote);
    }
  }, [template, predefinedServices, templateId]);

  const getLandingPageIntro = () => {
    if (!template) return '';
    if (template.landingPageIntro && template.landingPageIntro.trim() !== '') {
      return template.landingPageIntro;
    } else {
      const daysToExpire = template.daysToExpire || 30;
      return `Thanks for scanning your instant quote! We service some of your neighbors and thought we’d give you a quote. This quote expires in ${daysToExpire} days, so no pressure!`;
    }
  };

// Enhanced getServiceDetails function
const getServiceDetails = (serviceName: string): { service: Service; isPredefined: boolean } | undefined => {
  // First, attempt to find the predefined service by displayName
  const predefinedServiceByDisplayName = predefinedServices.find(s => s.displayName === serviceName);
  if (predefinedServiceByDisplayName) {
    return { service: predefinedServiceByDisplayName, isPredefined: true };
  }

  // If not found, attempt to find the predefined service by name
  const predefinedServiceByName = predefinedServices.find(s => s.name === serviceName);
  if (predefinedServiceByName) {
    return { service: predefinedServiceByName, isPredefined: true };
  }

  // If not found in predefined, attempt to find in custom services by name
  const customService = template?.customServices.find(s => s.name === serviceName);
  if (customService) {
    return { service: customService, isPredefined: false };
  }

  // Service not found
  console.warn(`Service "${serviceName}" not found in predefined or custom services.`);
  return undefined;
};
  // **Calculate Days Until Expiration**
  const getDaysUntilExpiration = () => {
    if (!quote) return null;
    const expirationDate = new Date(quote.expirationTime);
    const currentDate = new Date();
    const timeDiff = expirationDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff > 0 ? daysDiff : 0;
  };

  const daysUntilExpiration = getDaysUntilExpiration();

  // **Loading State**
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !template || !companySettings || !quote) {
    return (
      <Box sx={{ p: 4 }}>
        {/* <Alert severity="error">{error || 'An unexpected error occurred.'}</Alert> */}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        bgcolor: 'background.default',
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          width: '100%',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Back Button and Text aligned to the left */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack} sx={{ mr: 1, width: 30 }}>
            <ArrowBack />
          </IconButton>
          <Typography>Back to Templates</Typography>
        </Box>
      </Box>

      {/* Cowboy Hat Logo centered below the header */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <CowboyHatLogo />
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: { xs: 3, sm: 4 },
        }}
      >
        {/* Grid Container */}
        <Grid container spacing={2}>
          {isMobile ? (
            <>
              {/* Mobile Layout Content */}
              <Grid item xs={12}>
                {/* Greeting */}
                <Typography variant="h3">Hi there!</Typography>
                {getLandingPageIntro() && (
                  <Typography variant="body1" paragraph>
                    {getLandingPageIntro()}
                  </Typography>
                )}

                {/* Quote Section */}
                <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
                  Your Instant Quote:
                </Typography>

                {/* The Quote */}
                <Paper
                  elevation={3}
                  sx={{
                    padding: { xs: 2, sm: 3 },
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    mb: 4,
                    bgcolor: '#ffffff',
                    color: '#eoeoeo',
                  }}
                >
                  {quote.status === 'Accepted' && (
                    <Chip
                      label="Accepted"
                      color="success"
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: -15,
                        transform: 'rotate(45deg)',
                      }}
                    />
                  )}
                  {/* Quote Preview Label */}
                  {daysUntilExpiration !== null && quote.status !== 'Accepted' && (
                    <Chip
                      label={`Quote Preview`}
                      color="warning"
                      sx={{
                        position: 'absolute',
                        top: 30,
                        left: -20,
                        transform: 'rotate(-45deg)',
                      }}
                    />
                  )}
                  <Box sx={{ flexGrow: 1 }}>
                    {/* Company Logo */}
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {companySettings?.companyLogo && (
                        <Box
                          sx={{
                            mt: 4,
                            mb: 3,
                            maxWidth: '100%',
                            height: '80px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={`${companySettings.companyLogo.replace(/^\//, '')}`}
                            alt="Company Logo"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    {/* Template Name */}
                    {template.customization.showTitle && (
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ color: template.customization.primaryColor }}
                    >
                      {template.name}
                    </Typography>
                    )}
                    {template.customization.showIntro && (
                      <Typography variant="body1" paragraph>
                        {template.introParagraph}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                    {quote.services.map((serviceName) => {
                        const serviceInfo = getServiceDetails(serviceName);
                        return (
                          serviceInfo && (
                            <Grid item xs={12} key={serviceName}>
                              {/* Grid Container for Service Name and Value */}
                              <Grid container alignItems="center" sx={{ mb: 1 }}> {/* Optional: Adds margin below each row */}
                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: template.customization.secondaryColor }}
                                  >
                                     {serviceInfo.service.displayName || serviceInfo.service.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <Typography variant="body1" sx={{ color: '#1E1F20' }}>
                                    ${quote.serviceValues[quote.services.indexOf(serviceName)]}
                                  </Typography>
                                </Grid>
                              </Grid>

                              {/* Service Description */}
                              <ServiceDescription>
                                {parse(
                                  DOMPurify.sanitize(
                                    serviceInfo.isPredefined 
                                      ? (serviceInfo.service.quotePageDesc || serviceInfo.service.description)
                                      : serviceInfo.service.description
                                  )
                                )}
                              </ServiceDescription>
                            </Grid>
                          )
                        );
                      })}
</Grid>
                  </Box>

                  {/* Quote Total and Terms */}
                  <Box sx={{ mt: 'auto', pt: 4 }}>
                    <Typography
                      variant="h5"
                      align="right"
                      style={{ marginBottom: 20 }}
                      sx={{ color: '#1E1F20' }}
                    >
                      Total: ${quote.totalValue.toFixed(2)}
                    </Typography>

                   {/*  // Updated Terms and Conditions section */}
                    <Typography variant="h6" gutterBottom sx={{color: '#1E1F20'}}>
                      Terms and Conditions:
                    </Typography>
                    {quote.services.map((serviceName, index) => {
                      const serviceInfo = getServiceDetails(serviceName);
                      return serviceInfo?.service.fineText ? (
                        <Typography key={index} variant="body2" paragraph sx={{color: '#1E1F20'}}>
                         {/*  {serviceName}: */} {serviceInfo.service.fineText}
                        </Typography>
                      ) : null;
                    })}

                    {/* Action Button */}
                    {quote.status !== 'Accepted' && quote.totalValue !== 0 && (
                      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: template.customization.primaryColor,
                            color: 'white',
                            '&:hover': {
                              backgroundColor: lighten(
                                template.customization.primaryColor,
                                0.2
                              ),
                            },
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          Enter Information and Sign Electronically
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>

                {/* Company Information */}
           {companySettings?.whoWeAreText && (
              <>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Who We Are:
                </Typography>
                <Typography variant="body1" paragraph>
                  {companySettings.whoWeAreText}
                </Typography>
              </>
            )}

                {/* Website Link */}
                {companySettings?.websiteLink && (
                  <Button
                    onClick={() => window.open(companySettings.websiteLink, '_blank')}
                    sx={{
                      backgroundColor: template.customization.primaryColor,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: lighten(
                          template.customization.primaryColor,
                          0.2
                        ),
                      },
                      mb: 2,
                    }}
                  >
                    Visit our website
                  </Button>
                )}

                {/* Google Map Embed */}
                {companySettings?.googleMapEmbed && (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                    dangerouslySetInnerHTML={{ __html: companySettings.googleMapEmbed }}
                  />
                )}

                {/* User Information */}
                {userData?.whoIAmText && (
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Who I Am:
                </Typography>
                )}
                {userData?.whoIAmText && (
                  <Typography variant="body1" paragraph>
                    {userData.whoIAmText}
                  </Typography>
                )}

                {/* Video Section */}
                {quote.videoPath =='' && (
                  <Box sx={{ mt: 2 }}>
                    <video width="100%" controls>
                      <source
                        src={`${process.env.REACT_APP_API_URL}${quote.videoPath}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                {/* Mobile Footer */}
                {/* You can add mobile-specific footer content here if needed */}
              </Grid>
              {/* ... */}
            </>
          ) : (
            <>
              {/* Desktop Layout Content */}
              <Grid item xs={12} md={6}>
                {/* Greeting */}
                <Typography variant="h3">Hi there!</Typography>
                {getLandingPageIntro() && (
                  <Typography variant="body1" paragraph>
                    {getLandingPageIntro()}
                  </Typography>
                )}

                {/* Company Information */}
                {companySettings?.whoWeAreText && (
                  <>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Who We Are:
                </Typography>
               
                  <Typography variant="body1" paragraph>
                    {companySettings.whoWeAreText}
                  </Typography>
                  </>
                )}

                {/* Website Link */}
                {companySettings?.websiteLink && (
                  <Button
                    onClick={() => window.open(companySettings.websiteLink, '_blank')}
                    sx={{
                      backgroundColor: template.customization.primaryColor,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: lighten(
                          template.customization.primaryColor,
                          0.2
                        ),
                      },
                    }}
                  >
                    Visit our website
                  </Button>
                )}

                {/* Google Map and Video Sections */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    {companySettings?.googleMapEmbed && (
                      <Box
                        sx={{
                          mt: 2,
                          mb: 2,
                          position: 'relative',
                          overflow: 'hidden',
                          paddingTop: '56.25%', // 16:9 aspect ratio
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          dangerouslySetInnerHTML={{ __html: companySettings.googleMapEmbed }}
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {quote.videoPath =='' && (
                      <Box
                        sx={{
                          mt: 2,
                          mb: 2,
                          position: 'relative',
                          overflow: 'hidden',
                          paddingTop: '56.25%', // 16:9 aspect ratio
                        }}
                      >
                        <video
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          controls
                        >
                          <source
                            src={`${process.env.REACT_APP_API_URL}${quote.videoPath}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </Box>
                    )}
                  </Grid>
                </Grid>

                {/* User Information */}
                {userData?.whoIAmText && (
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Who I Am:
                </Typography>
                )}
                {userData?.whoIAmText && (
                  
                  <Typography variant="body1" paragraph>
                    {userData.whoIAmText}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Right side content: The rendered quote */}
                <Paper
                  elevation={3}
                  sx={{
                    padding: { xs: 2, sm: 3 },
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: 1,
                    width: 0.8,
                    ml: 10,
                    bgcolor: '#ffffff',
                    color: '#eoeoeo',
                  }}
                >
                  {quote.status === 'Accepted' && (
                    <Chip
                      label="Accepted"
                      color="success"
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: -15,
                        transform: 'rotate(45deg)',
                      }}
                    />
                  )}
                  {/* Quote Preview Label */}
                  {daysUntilExpiration !== null && quote.status !== 'Accepted' && (
                    <Chip
                      label={`Quote Preview`}
                      color="warning"
                      sx={{
                        position: 'absolute',
                        top: 20,
                        left: -25,
                        transform: 'rotate(-45deg)',
                      }}
                    />
                  )}
                  <Box sx={{ flexGrow: 1 }}>
                    {/* Company Logo */}
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {companySettings?.companyLogo && (
                        <Box
                          sx={{
                            mt: 4,
                            mb: 3,
                            maxWidth: '100%',
                            height: '80px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={`${companySettings.companyLogo.replace(/^\//, '')}`}
                            alt="Company Logo"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    {/* Template Name */}
                    {template.customization.showTitle && (
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ color: template.customization.primaryColor }}
                    >
                      {template.name}
                    </Typography>
                    )}
                    {template.customization.showIntro && (
                      <Typography variant="body1" paragraph sx={{color:'#000'}}>
                        {template.introParagraph}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                    {quote.services.map((serviceName) => {
                        const serviceInfo = getServiceDetails(serviceName);
                        return (
                          serviceInfo && (
                            <Grid item xs={12} key={serviceName}>
                              {/* Grid Container for Service Name and Value */}
                              <Grid container alignItems="center" sx={{ mb: 1 }}> {/* Optional: Adds margin below each row */}
                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: template.customization.secondaryColor }}
                                  >
                                    {serviceInfo.service.displayName || serviceInfo.service.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <Typography variant="body1" sx={{ color: '#1E1F20' }}>
                                    ${quote.serviceValues[quote.services.indexOf(serviceName)]}
                                  </Typography>
                                </Grid>
                              </Grid>

                              {/* Service Description */}
                              <ServiceDescription>
                                {parse(
                                  DOMPurify.sanitize(
                                    serviceInfo.isPredefined 
                                      ? (serviceInfo.service.quotePageDesc || serviceInfo.service.description)
                                      : serviceInfo.service.description
                                  )
                                )}
                              </ServiceDescription>
                            </Grid>
                          )
                        );
                      })}
</Grid>
                  </Box>

                  {/* Quote Total and Terms */}
                  <Box sx={{ mt: 'auto', pt: 4 }}>
                    <Typography
                      variant="h5"
                      align="right"
                      style={{ marginBottom: 20, color: '#1E1F20' }}
                    >
                      Total: ${quote.totalValue.toFixed(2)}
                    </Typography>

                    {/* // Updated Terms and Conditions section */}
                    <Typography variant="h6" gutterBottom sx={{color: '#1E1F20'}}>
                      Terms and Conditions:
                    </Typography>
                    {quote.services.map((serviceName, index) => {
                      const serviceInfo = getServiceDetails(serviceName);
                      return serviceInfo?.service.fineText ? (
                        <Typography key={index} variant="body2" paragraph sx={{color: '#1E1F20'}}>
                          {/* {serviceName}:  */}{serviceInfo.service.fineText}
                        </Typography>
                      ) : null;
                    })}

                    {/* Action Button */}
                    {quote.status !== 'Accepted' && quote.totalValue !== 0 && (
                      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: template.customization.primaryColor,
                            color: 'white',
                            '&:hover': {
                              backgroundColor: lighten(
                                template.customization.primaryColor,
                                0.2
                              ),
                            },
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          Enter Information and Sign Electronically
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
              {/* ... */}
            </>
          )}
        </Grid>

        {/* Floating Action Buttons */}
        {quote.status === 'Accepted' && (
          <Box
            sx={{
              position: 'fixed',
              right: 20,
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Tooltip title="Download PDF">
              <IconButton>
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Email">
              <IconButton>
                <Email />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default QuoteTemplatePage;
