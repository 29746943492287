// src/components/TrailGuide/ArticleCards.tsx

import React from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

/**
 * Interface for Article objects.
 */
interface Article {
  id: string; // Changed to string for consistency
  title: string;
  category: 'articles';
  excerpt: string;
  content: string;
  link: string;
  featured_media_url?: string;
  date: string;
  isNew?: boolean;
}

/**
 * Props for the ArticleCards component.
 */
interface ArticleCardsProps {
  articles: Article[];
}

/**
 * Styled Card component for consistent styling.
 */
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

/**
 * ArticleCards Component
 * Displays a grid of article cards.
 */
const ArticleCards: React.FC<ArticleCardsProps> = ({ articles }) => {
  return (
    <Grid container spacing={4}>
      {articles.map((article) => (
        <Grid item key={article.id} xs={12} sm={6} md={4}>
          <StyledCard>
            {article.featured_media_url ? (
              <CardMedia
                component="img"
                height="180"
                image={article.featured_media_url}
                alt={article.title}
                loading="lazy"
              />
            ) : (
              <CardMedia
                component="img"
                height="180"
                image="/images/placeholder.jpg" // Ensure this path is correct or use an online placeholder
                alt="No Image Available"
                loading="lazy"
              />
            )}
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {article.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {article.excerpt}
              </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                size="medium"
                color='warning'
                variant='contained'
                href={article.link}
                target="_blank"
                rel="noopener"
                aria-label={`Read more about ${article.title}`}
                sx={{width:.95, mb:1}}
              >
                Read More
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default ArticleCards;