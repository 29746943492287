// src/components/TrailGuide/FAQList.tsx

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqsData from '../data/faqs.json';

interface FAQ {
  question: string;
  answer: string;
}

interface FAQCategory {
  category: string;
  questions: FAQ[];
}

interface FAQListProps {
  searchQuery: string;
}

const FAQList: React.FC<FAQListProps> = ({ searchQuery }) => {
  const { faqs } = faqsData as { faqs: FAQCategory[] };

  // Function to filter FAQs based on the search query
  const getFilteredFaqs = () => {
    if (!searchQuery.trim()) {
      return faqs;
    }

    const lowerCaseQuery = searchQuery.toLowerCase();

    return faqs
      .map((faqCategory) => {
        const filteredQuestions = faqCategory.questions.filter(
          (faq) =>
            faq.question.toLowerCase().includes(lowerCaseQuery) ||
            faq.answer.toLowerCase().includes(lowerCaseQuery)
        );

        return { ...faqCategory, questions: filteredQuestions };
      })
      .filter((faqCategory) => faqCategory.questions.length > 0); // Remove categories with no matching questions
  };

  const filteredFaqs = getFilteredFaqs();

  return (
    <Box sx={{ mt: 4 }}>
      {filteredFaqs.length === 0 ? (
        <Typography variant="h6" align="center">
          No FAQs match your search.
        </Typography>
      ) : (
        filteredFaqs.map((faqCategory) => (
          <Box key={faqCategory.category} sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              {faqCategory.category}
            </Typography>
            {faqCategory.questions.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                >
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ))
      )}
    </Box>
  );
};

export default FAQList;