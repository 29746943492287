import React, { useState, useRef } from 'react';
import { 
  Button, Menu, MenuItem, Box, Typography 
} from '@mui/material';
import { FilterList, ArrowDropDown } from '@mui/icons-material';

interface DateFilterOption {
  label: string;
  value: number | null;
}

interface CustomFilterProps {
  dateFilterOptions: DateFilterOption[];
  currentValue: number | null;
  onChange: (value: number | null) => void;
}

/**
 * A custom date filter button that:
 *  - Shows a filter icon on the left
 *  - Displays two lines of text (e.g., "LAST 30" over "DAYS")
 *  - Has a dropdown arrow on the right
 *  - Opens a Material UI Menu when clicked
 */
const CustomFilter: React.FC<CustomFilterProps> = ({
  dateFilterOptions,
  currentValue,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Open the menu
  const handleOpen = () => {
    setAnchorEl(buttonRef.current);
  };

  // Close the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle selecting a different date filter
  const handleSelect = (value: number | null) => {
    onChange(value);
    handleClose();
  };

  // Find the currently selected option’s label
  const selectedOption = dateFilterOptions.find(opt => opt.value === currentValue);
  const label = selectedOption?.label || 'All Time';

  /**
   * Optional: Parse the label into top & bottom lines
   * For "Last 30 days" => top: "LAST 30", bottom: "DAYS"
   * For "All Time"     => top: "ALL",      bottom: "TIME"
   * etc.
   */
  const parts = label.toUpperCase().split(' ');

  let topLine = '';
  let bottomLine = '';
  if (parts.length >= 3) {
    // e.g. ["LAST", "30", "DAYS"]
    topLine = parts[0] + ' ' + parts[1]; // "LAST 30"
    bottomLine = parts[2];              // "DAYS"
  } else if (parts.length === 2) {
    // e.g. ["ALL", "TIME"] => top: "ALL", bottom: "TIME"
    topLine = parts[0];
    bottomLine = parts[1];
  } else {
    // Single word or no label found
    topLine = parts[0] ?? 'LAST 30';
    bottomLine = parts[1] ?? '';
  }

  return (
    <>
      <Button
        ref={buttonRef}
        variant="contained"
        onClick={handleOpen}
        sx={{
          backgroundColor: '#2B6575',
          color: '#FFF',
          borderRadius: '8px',
          minWidth: '80px',
          width: '200px',
          padding: '6px 10px',
          '&:hover': {
            backgroundColor: '#24525d',
          },
        }}
      >
        <Box display="flex" alignItems="center">
          {/* Left icon */}
          <FilterList sx={{ fontSize: 18, marginRight: 4 }} />

          {/* Two-line text in the middle */}
          <Box display="flex" flexDirection="column" alignItems="center" mr={1}>
            <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 700, lineHeight: '14px' }}>
              {topLine}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 700, lineHeight: '14px' }}>
              {bottomLine}
            </Typography>
          </Box>

          {/* Right arrow */}
          <ArrowDropDown sx={{ fontSize: 18 }} />
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {dateFilterOptions.map((opt) => (
          <MenuItem
            key={opt.label}
            selected={opt.value === currentValue}
            onClick={() => handleSelect(opt.value)}
          >
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CustomFilter;