import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  TextField,
  MenuItem,
} from '@mui/material';
import axiosInstance from '../../utils/axiosConfig';
import CampaignMap, { CampaignCustomer } from './CampaignMap';

interface Campaign {
    campaignId: string;
    name: string;
    customers: Array<{
      name: string;
      address: {
        street: string;
        city: string;
        state: string;
        zip: string;
      };
      location: {
        coordinates: [number, number]; // Explicit tuple type
      };
      color?: string;
    }>;
  }

const ViewCampaign: React.FC = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState(true);
  const [mapProvider, setMapProvider] = useState<'google' | 'apple'>('google');
  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/campaigns/${campaignId}/detail`);
        setCampaign(response.data.campaign);
        
        // Set initial map center to first customer
        if (response.data.campaign?.customers?.length) {
          const [lon, lat] = response.data.campaign.customers[0].location.coordinates;
          setMapCenter([lat, lon]);
        }
      } catch (error) {
        console.error('Error fetching campaign:', error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [campaignId, navigate]);

  const handleAddressClick = (coordinates: number[], address: string) => {
    const [lon, lat] = coordinates;
    const url = mapProvider === 'apple' ?
      `http://maps.apple.com/?daddr=${encodeURIComponent(address)}` :
      `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
    
    window.open(url, '_blank');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!campaign) {
    return (
      <Box p={3}>
        <Typography variant="h6">Campaign not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}

      
      <Box sx={{ p: 2, borderBottom: '1px solid #ddd' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{campaign.name}</Typography>
          <TextField
            select
            size="small"
            value={mapProvider}
            onChange={(e) => setMapProvider(e.target.value as 'google' | 'apple')}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="google">Google Maps</MenuItem>
            <MenuItem value="apple">Apple Maps</MenuItem>
          </TextField>
        </Box>
      </Box>

      {/* Map */}
      <Box sx={{ height: '40vh', width: '100%' }}>
        <CampaignMap
          customers={campaign.customers.map(c => ({
            name: c.name,
            address: c.address,
            location: { type: 'Point', coordinates: c.location.coordinates },
            color: c.color
          }))}
          center={mapCenter}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
        />
      </Box>

      {/* Customer List */}
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        <List>
          {campaign.customers.map((customer, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      backgroundColor: customer.color || '#666',
                      mr: 2
                    }}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1">{customer.name}</Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ color: 'warning.main', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => handleAddressClick(
                        customer.location.coordinates,
                        `${customer.address.street}, ${customer.address.city}, ${customer.address.state} ${customer.address.zip}`
                      )}
                    >
                      {customer.address.street}, {customer.address.city}, {customer.address.state} {customer.address.zip}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ViewCampaign;