// utils/wordpressApi.ts
import axios from 'axios';

const WORDPRESS_BASE_URL = 'https://cowboyquote.com/wp-json/wp/v2';

export const fetchWordPressPosts = async () => {
  try {
    const response = await axios.get(`${WORDPRESS_BASE_URL}/posts`, {
      params: {
        per_page: 100, // Fetch up to 100 posts; adjust as needed
        _embed: true,  // To include featured media
        order: 'desc', // Ensure most recent posts first
        orderby: 'date',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching WordPress posts:', error);
    return [];
  }
};