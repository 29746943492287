// src/components/TrailGuide/AnnouncementsList.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
} from '@mui/material';
import { styled, useTheme, Theme } from '@mui/material/styles';
import axiosInstance from '../../utils/axiosConfig';
import { decodeHtml } from '../../utils/decodeHtml'; // Ensure this utility exists
import { useSnackbar } from 'notistack';
import {
  Announcement as AnnouncementIcon,
  Build as MaintenanceIcon,
  NewReleases as FeatureIcon,
  Warning as AlertIcon,
} from '@mui/icons-material';

// Define the Notification interface
interface Notification {
  _id: string;
  title: string;
  message: string;
  type: 'ANNOUNCEMENT' | 'MAINTENANCE' | 'FEATURE' | 'ALERT';
  createdAt: string;
  expiresAt: string | null;
  isRead: boolean;
}

// Define the allowed palette color keys
type PaletteColorKeys = 'primary' | 'warning' | 'success' | 'error';

// Define color and icon mapping for notification types
const typeSettings = {
  ANNOUNCEMENT: {
    color: 'primary' as PaletteColorKeys,
    icon: <AnnouncementIcon />,
  },
  MAINTENANCE: {
    color: 'warning' as PaletteColorKeys,
    icon: <MaintenanceIcon />,
  },
  FEATURE: {
    color: 'success' as PaletteColorKeys,
    icon: <FeatureIcon />,
  },
  ALERT: {
    color: 'error' as PaletteColorKeys,
    icon: <AlertIcon />,
  },
} as const;

// Styled components
const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  margin: '0 auto',
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'bordercolor', // Prevent forwarding
})<{ bordercolor: PaletteColorKeys }>(({ theme, bordercolor }) => ({
  marginBottom: theme.spacing(3),
  borderLeft: `6px solid ${theme.palette[bordercolor].main}`, // Correctly typed
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const AnnouncementsList: React.FC = () => {
  const [announcements, setAnnouncements] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setLoading(true);
      try {
        const userDataString = localStorage.getItem('userData');
        if (!userDataString) {
          throw new Error('User data not found');
        }
        const userData = JSON.parse(userDataString);
        const companyName = userData.companyName;

        const response = await axiosInstance.get(`/api/notifications/company/${companyName}`);
        const fetchedAnnouncements: Notification[] = response.data;

        // Sort announcements by descending createdAt
        const sortedAnnouncements = fetchedAnnouncements.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setAnnouncements(sortedAnnouncements);
      } catch (err: any) {
        console.error('Error fetching announcements:', err);
        setError(err.message || 'Error fetching announcements');
        enqueueSnackbar('Failed to load announcements.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, [enqueueSnackbar]);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress color="warning" />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ my: 4 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && announcements.length === 0 && (
        <Typography variant="body1" align="center" color="textSecondary" sx={{ py: 4 }}>
          No announcements available.
        </Typography>
      )}

      {!loading && !error && announcements.length > 0 && (
        <StyledGrid container spacing={4}>
          {announcements.map((announcement) => (
            <Grid item xs={12} sm={6} md={4} key={announcement._id}>
              <StyledCard bordercolor={typeSettings[announcement.type].color}>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label={`${announcement.type} icon`}
                      sx={{
                        bgcolor: `${typeSettings[announcement.type].color}.light`,
                        color: `${typeSettings[announcement.type].color}.main`,
                      }}
                    >
                      {typeSettings[announcement.type].icon}
                    </Avatar>
                  }
                  title={
                    <Typography variant="h6" color="textPrimary">
                      {decodeHtml(announcement.title)}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption" color="textSecondary">
                      {formatDate(announcement.createdAt)}
                    </Typography>
                  }
                />
                <CardContent>
                  <Typography variant="body2" color="textSecondary">
                    {decodeHtml(announcement.message)}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </StyledGrid>
      )}
    </Box>
  );
};

export default AnnouncementsList;