import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import axiosInstance from '../utils/axiosConfig';
import RoleCell from './RoleCell';
import RoleChangeModal from './RoleChangeModal';
import { ColorLens } from '@mui/icons-material';
import UserChip from './UserChip';

type RoleType = 'Owner' | 'Admin' | 'User';

interface User {
  userId: string;
  name: string;
  email: string;
  role: RoleType;
  isVerified: boolean;
  createdAt: string;
  userColor: string;
}

interface NewUser {
  name: string;
  email: string;
  role: string;
}

const CompanyUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionLoading, setActionLoading] = useState<string | null>(null);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [newUser, setNewUser] = useState<NewUser>({
    name: '',
    email: '',
    role: 'User',
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
const [userToDelete, setUserToDelete] = useState<User | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/users/company');
      setUsers(response.data);
    } catch (error: any) {
      setError('Failed to fetch users');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async () => {
    try {
      setError(null);
      const response = await axiosInstance.post('/api/users/create', newUser);
      setSuccess('User added successfully');
      setOpenDialog(false);
      setNewUser({ name: '', email: '', role: 'User' });
      fetchUsers();
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to add user');
      console.error('Error adding user:', error);
    }
  };

  const handleDeleteUser = async (user: User) => {
    try {
      setActionLoading(user.userId);
      setError(null);
      await axiosInstance.delete(`/api/users/${user.userId}`);
      setUsers(currentUsers => currentUsers.filter(u => u.userId !== user.userId));
      setSuccess('User deleted successfully');
      setDeleteDialogOpen(false);
      setUserToDelete(null);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to delete user');
      console.error('Error deleting user:', error);
    } finally {
      setActionLoading(null);
    }
  };

  const handleResendInvitation = async (userId: string) => {
    try {
      setActionLoading(userId);
      setError(null);
      await axiosInstance.post(`/api/users/${userId}/resend-invitation`);
      setSuccess('Invitation email resent successfully');
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to resend invitation');
      console.error('Error resending invitation:', error);
    } finally {
      setActionLoading(null);
    }
  };

  const handleCancelInvitation = async (userId: string) => {
    try {
      setActionLoading(userId);
      setError(null);
      await axiosInstance.post(`/api/users/${userId}/cancel-invitation`);
      setSuccess('Invitation cancelled successfully');
      fetchUsers(); // Refresh the user list
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to cancel invitation');
      console.error('Error cancelling invitation:', error);
    } finally {
      setActionLoading(null);
    }
  };

  // Add the handler for color updates
const handleColorUpdate = async (userId: string, newColor: string) => {
  try {
    setActionLoading(userId);
    setError(null);
    
    await axiosInstance.put(`/api/users/${userId}/color`, {
      userColor: newColor
    });
    
    setUsers(currentUsers =>
      currentUsers.map(u =>
        u.userId === userId ? { ...u, userColor: newColor } : u
      )
    );
    
    setSuccess('Cowboy color updated successfully');
    setColorModalOpen(false);
    setSelectedUserId(null);
  } catch (error: any) {
    setError(error.response?.data?.message || 'Failed to update cowboy color');
    fetchUsers();
  } finally {
    setActionLoading(null);
  }
};


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }



  const ColorPickerModal: React.FC<{
    open: boolean;
    onClose: () => void;
    currentColor: string;
    userId: string;
    onSave: (userId: string, color: string) => void;
  }> = ({ open, onClose, currentColor, userId, onSave }) => {
    const [selectedColor, setSelectedColor] = useState(currentColor);
    const hasChanged = selectedColor !== currentColor;
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Customize Cowboy Color</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ColorLens sx={{ color: selectedColor, mr: 1 }} />
              <input
                type="color"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                style={{ width: '100%', height: '40px' }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="info">
            Cancel
          </Button>
          <Button
            onClick={() => onSave(userId, selectedColor)}
            variant="contained"
            color="warning"
            disabled={!hasChanged}
          >
            Confirm Color
          </Button>
        </DialogActions>
      </Dialog>
      
    );
  };

  return (
    <Box>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Manage Users</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setOpenDialog(true)}
          sx={{
            bgcolor: 'warning.main',
            '&:hover': {
              bgcolor: 'warning.dark',
            },
            width: 200,
          }}
        >
          Add User
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
  <TableRow key={user.userId}>
    <TableCell>
    <UserChip
          userId={user.userId}
          userName={user.name}
          userColor={user.userColor}
        />
    </TableCell>
    <TableCell>{user.email}</TableCell>
    <TableCell>
  <RoleCell 
    user={user}
    onRoleUpdate={async (userId, newRole) => {
      try {
        setActionLoading(userId);
        setError(null);
        
        // Call the new dedicated role update endpoint
        await axiosInstance.put(`/api/users/${userId}/role`, {
          role: newRole
        });
        
        // Update local state
        setUsers(currentUsers =>
          currentUsers.map(u =>
            u.userId === userId ? { ...u, role: newRole } : u
          )
        );
        
        setSuccess('User role updated successfully');
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to update user role');
        // Re-fetch users to ensure consistency
        fetchUsers();
      } finally {
        setActionLoading(null);
      }
    }}
  />
</TableCell>
    <TableCell>{user.isVerified ? 'Verified' : 'Pending'}</TableCell>
    <TableCell>{new Date(user.createdAt).toLocaleDateString()}</TableCell>
    {/* Actions */}
    <TableCell align="center">
  <Box sx={{ display: 'flex', justifyContent: 'left', gap: 1 }}>
    {!user.isVerified ? (
      // Show invitation buttons for unverified users
      <>
        <Tooltip title="Resend Invitation">
          <IconButton 
            size="small"
            onClick={() => handleResendInvitation(user.userId)}
            disabled={actionLoading === user.userId}
            sx={{ 
              color: 'info.main',
              '&:hover': { bgcolor: 'warning.main' },
              width: 35
            }}
          >
            {actionLoading === user.userId ? (
              <CircularProgress size={20} />
            ) : (
              <EmailIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel Invitation">
          <IconButton 
            size="small"
            onClick={() => handleCancelInvitation(user.userId)}
            disabled={actionLoading === user.userId}
            sx={{ 
              color: 'error.main',
              '&:hover': { bgcolor: '#ff000' },
              width: 35
            }}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      // Show delete button only for verified users who aren't Owners
      <Tooltip title="Delete User">
        <IconButton
          size="small"
          onClick={() => {
            setUserToDelete(user);
            setDeleteDialogOpen(true);
          }}
          disabled={actionLoading === user.userId || user.role === 'Owner'}
          sx={{ 
            color: 'error.main',
            '&:hover': { bgcolor: 'error.light' },
            width: 35
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
    
    {/* Cowboy Color button - always shown */}
    <Tooltip title="Customize Cowboy Color">
      <IconButton
        size="small"
        onClick={() => {
          setSelectedUserId(user.userId);
          setColorModalOpen(true);
        }}
        disabled={actionLoading === user.userId}
        sx={{ 
          width: 35,
          height: 35,
          p: 0,
          position: 'relative',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.1)'
          }
        }}
      >
        <Box sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}>
          <img 
            src="/userColor.png" 
            alt="User Color Icon"
            style={{ 
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: user.userColor || '#f37121',
            mixBlendMode: 'color',
            maskImage: 'url(/userColor.png)',
            maskSize: '100%',
            WebkitMaskImage: 'url(/userColor.png)',
            WebkitMaskSize: '100%',
          }} />
        </Box>
      </IconButton>
    </Tooltip>
  </Box>
</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Name"
              value={newUser.name}
              onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
              fullWidth
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'warning.main',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'warning.main',
                },
              }}
            />
            <TextField
              label="Email"
              type="email"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              fullWidth
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'warning.main',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'warning.main',
                },
              }}
            />
            <TextField
              select
              label="Role"
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              fullWidth
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'warning.main',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'warning.main',
                },
              }}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}
            variant='contained'
            sx={{
              bgcolor: 'info.main',
              '&:hover': {
                bgcolor: 'info.dark',
              },
            }}>Cancel</Button>
          <Button 
            onClick={handleAddUser} 
            variant="contained" 
            sx={{
              bgcolor: 'warning.main',
              '&:hover': {
                bgcolor: 'warning.dark',
              },
            }}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
      <ColorPickerModal
      open={colorModalOpen}
      onClose={() => {
        setColorModalOpen(false);
        setSelectedUserId(null);
      }}
      currentColor={users.find(u => u.userId === selectedUserId)?.userColor || '#f37121'}
      userId={selectedUserId || ''}
      onSave={handleColorUpdate}
    />
    <Dialog 
  open={deleteDialogOpen} 
  onClose={() => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  }}
>
  <DialogTitle>Delete User</DialogTitle>
  <DialogContent>
    <Typography>
      Are you sure you want to delete {userToDelete?.name}? This action cannot be undone.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button 
      onClick={() => {
        setDeleteDialogOpen(false);
        setUserToDelete(null);
      }}
      variant="contained"
      color="info"
    >
      Cancel
    </Button>
    <Button
      onClick={() => userToDelete && handleDeleteUser(userToDelete)}
      variant="contained"
      color="error"
      disabled={actionLoading === userToDelete?.userId}
    >
      {actionLoading === userToDelete?.userId ? 'Deleting...' : 'Delete'}
    </Button>
  </DialogActions>
</Dialog>
    </Box>
  );
};

export default CompanyUsers;