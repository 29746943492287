//components/UserChip.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Chip, Avatar, Box, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';

interface UserChipProps {
  userId: string;
  userName: string;
  userColor: string;
  size?: 'small' | 'medium';
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
  showAvatar?: boolean;
  disabled?: boolean;
  className?: string;
}

const UserChip: React.FC<UserChipProps> = ({
  userId,
  userName,
  userColor,
  size = 'small',
  onClick,
  style,
  showAvatar = true,
  disabled = false,
  className
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    
    if (onClick) {
      onClick(e);
    } else {
      navigate(`/dashboard/users/${userId}`);
    }

    // Prevent event bubbling
    e.stopPropagation();
  };

  return (
    <Tooltip title={disabled ? '' : 'View Profile'}>
      <Chip
        label={userName}
        size={size}
        onClick={handleClick}
        className={className}
        sx={{
          cursor: disabled ? 'default' : 'pointer',
          backgroundColor: alpha(userColor || '#666', 1),
          color: '#fff',
          borderRadius: '16px',
          height: '24px',
          '& .MuiChip-label': {
            px: 1,
          },
          '&:hover': {
            backgroundColor: disabled ? alpha(userColor || '#666', 0.1) : alpha(userColor || '#666', 0.2),
          },
          ...style
        }}
      />
    </Tooltip>
  );
};

export default UserChip;