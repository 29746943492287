// components/TrailGuide/SearchBar.tsx
import React from 'react';
import { Paper, IconButton, InputBase } from '@mui/material';
import { Search } from '@mui/icons-material';

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchQuery, setSearchQuery }) => {
  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: { xs: '100%', sm: '80%', md: '60%' },
        mx: 'auto',
        mb: 4,
      }}
    >
      <IconButton sx={{ p: '10px', width: 40 }}>
        <Search />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search the Trail Guide..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </Paper>
  );
};

export default SearchBar;