import React, { createContext, useState, useContext, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

interface AuthContextType {
  isAuthenticated: boolean | undefined;
  isAdmin: boolean | undefined;
  isImpersonating: boolean;
  user: DecodedToken | null;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsAdmin: (isAdmin: boolean) => void;
  setIsImpersonating: (isImpersonating: boolean) => void;
}

interface DecodedToken {
  exp: number;
  cleanedCompanyName: string;
  isImpersonating?: boolean;
  companyName?: string;
  userId?: string;
  // Add other token fields if necessary
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
  const [user, setUser] = useState<DecodedToken | null>(null);

  useEffect(() => {
    console.log('AuthContext: Checking localStorage for tokens');
    const token =
      localStorage.getItem('impersonationToken') ||
      localStorage.getItem('token') ||
      localStorage.getItem('adminToken');
    const adminData = localStorage.getItem('adminData');

    let isTokenValid = false;
    if (token) {
      try {
        const decoded: DecodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          // Token expired
          console.log('AuthContext: Token expired');
          localStorage.removeItem('token');
          localStorage.removeItem('adminToken');
          localStorage.removeItem('impersonationToken');
          isTokenValid = false;
          setIsImpersonating(false);
          setUser(null);
        } else {
          isTokenValid = true;
          if (decoded.isImpersonating) {
            setIsImpersonating(true);
          } else {
            setIsImpersonating(false);
          }
          setUser(decoded);
        }
      } catch (error) {
        console.error('AuthContext: Token validation error:', error);
        isTokenValid = false;
        setIsImpersonating(false);
        setUser(null);
      }
    } else {
      isTokenValid = false;
      setIsImpersonating(false);
      setUser(null);
    }

    setIsAuthenticated(isTokenValid);
    setIsAdmin(!!adminData);

    console.log(
      'AuthContext: isAuthenticated:',
      isTokenValid,
      'isAdmin:',
      !!adminData,
      'isImpersonating:',
      isImpersonating
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Optional: Listen for token changes in localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const token =
        localStorage.getItem('impersonationToken') ||
        localStorage.getItem('token') ||
        localStorage.getItem('adminToken');
      const adminData = localStorage.getItem('adminData');

      let isTokenValid = false;
      if (token) {
        try {
          const decoded: DecodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decoded.exp < currentTime) {
            // Token expired
            console.log('AuthContext: Token expired');
            localStorage.removeItem('token');
            localStorage.removeItem('adminToken');
            localStorage.removeItem('impersonationToken');
            isTokenValid = false;
            setIsImpersonating(false);
            setUser(null);
          } else {
            isTokenValid = true;
            if (decoded.isImpersonating) {
              setIsImpersonating(true);
            } else {
              setIsImpersonating(false);
            }
            setUser(decoded);
          }
        } catch (error) {
          console.error('AuthContext: Token validation error:', error);
          isTokenValid = false;
          setIsImpersonating(false);
          setUser(null);
        }
      } else {
        isTokenValid = false;
        setIsImpersonating(false);
        setUser(null);
      }

      setIsAuthenticated(isTokenValid);
      setIsAdmin(!!adminData);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        isImpersonating,
        user,
        setIsAuthenticated,
        setIsAdmin,
        setIsImpersonating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};