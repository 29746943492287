// frontend/src/components/AdminLogout.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import adminAxiosInstance from '../utils/adminAxiosConfig';
import { CircularProgress, Box, Typography } from '@mui/material';

const AdminLogout: React.FC = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setIsAdmin } = useAuth();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Optionally, notify the backend about the logout
        await adminAxiosInstance.post('/api/admin/auth/logout');
        console.log('AdminLogout: Successfully logged out on the backend');
      } catch (error: any) {
        console.error('AdminLogout: Error logging out on the backend:', error.response?.data || error.message);
        // Even if backend logout fails, proceed with frontend logout
      } finally {
        // Clear admin tokens and data from localStorage
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminData');
        console.log('AdminLogout: Cleared adminToken and adminData from localStorage');

        // Update AuthContext to reflect logout
        setIsAuthenticated(false);
        setIsAdmin(false);
        console.log('AdminLogout: setIsAuthenticated(false) and setIsAdmin(false)');

        // Redirect to admin login page
        navigate('/admin/login');
      }
    };

    performLogout();
  }, [navigate, setIsAuthenticated, setIsAdmin]);

  // Optional: Display a loading indicator while logging out
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: 'background.default',
        p: 3,
      }}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Logging out...
      </Typography>
    </Box>
  );
};

export default AdminLogout;