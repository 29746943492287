// src/components/CustomerQuoteLanding.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  Modal,
  Chip,
  IconButton,
  Tooltip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import SignatureCanvas from 'react-signature-canvas';
import axiosInstance from '../utils/axiosConfig';
import { Download, Email } from '@mui/icons-material';
import { generateQuotePDF } from '../utils/pdfGenerator';
import { debounce } from 'lodash';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify'; 
import publicAxios from '../utils/publicAxios';
import CowboyHatLogo from './CowboyHatLogo';
import Footer from './Footer';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

interface Service {
  name: string;
  displayName?: string;
  description: string;
  quotePageDesc?: string;
  fineText?: string;
}

interface Quote {
  quoteId: string;
  templateId: string;
  services: string[];
  serviceValues: string[];
  totalValue: number;
  status: string;
  acceptedTime?: string;
  expirationTime: string;
  videoPath?: string; // Assuming videoPath is in the quote model
  userId: string;
}

interface QuoteTemplate {
  name: string;
  introParagraph: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  companyName: string;
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
    showTitle:boolean;
  };
  landingPageIntro?: string;
  daysToExpire: number;
}

interface CustomerData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature?: string;
}

interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string;
  whoWeAreText?: string;
  googleMapEmbed?: string;
  websiteLink?: string;
}

interface User {
  whoIAmText?: string;
}

interface Suggestion {
  display_name: string;
  address: any;
}

interface AddressDropdownProps {
  value: string;
  suggestions: Suggestion[];
  onChange: (value: string) => void;
  onSelect: (suggestion: Suggestion) => void;
  loading: boolean;
}

// Styled component for service description
const ServiceDescription = styled(Box)(({ theme }) => ({
  color: '#1E1F20',
  fontSize: '0.75rem', // 14px, reduced by approximately 2 points
  '& p': { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  '& ul, & ol': { marginTop: theme.spacing(1), marginBottom: theme.spacing(1), paddingLeft: theme.spacing(3) },
  '& li': { marginBottom: theme.spacing(0.5) },
  '& strong': { fontWeight: 'bold' },
  '& em': { fontStyle: 'italic' },
  wordBreak: 'break-word',
}));

// AddressDropdown component
const AddressDropdown: React.FC<AddressDropdownProps> = ({
  value,
  suggestions,
  onChange,
  onSelect,
  loading,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        label="Address"
        variant="outlined"
        fullWidth
        required
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          setShowSuggestions(true);
        }}
        onBlur={() => {
          // Delay hiding the suggestions to allow click events to register
          setTimeout(() => setShowSuggestions(false), 200);
        }}
        onFocus={() => {
          if (suggestions.length > 0) {
            setShowSuggestions(true);
          }
        }}
        helperText="Start typing your address and select from suggestions"
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'warning.main',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: 'warning.main', 
          },
        }}
      />
      {showSuggestions && (
        <Paper
          style={{
            position: 'absolute',
            zIndex: 100,
            left: 0,
            right: 0,
            maxHeight: '200px',
            overflowY: 'auto',
            backgroundColor: 'info.main', // Adjusted for better visibility
          }}
        >
          <List>
            {loading && (
              <ListItem>
                <ListItemText primary="Loading..." />
              </ListItem>
            )}
            {!loading && suggestions.length === 0 && (
              <ListItem>
                <ListItemText primary="No suggestions found" />
              </ListItem>
            )}
            {suggestions.map((suggestion, index) => (
              <ListItem
                button
                key={`${suggestion.display_name}-${index}`} // Unique key
                onMouseDown={() => {
                  onSelect(suggestion);
                  setShowSuggestions(false);
                }}
              >
                <ListItemText primary={suggestion.display_name} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

const CustomerQuoteLanding: React.FC = () => {
  const { quoteId, companyName } = useParams<{ quoteId?: string; companyName?: string }>();

  const [quote, setQuote] = useState<Quote | null>(null);
  const [template, setTemplate] = useState<QuoteTemplate | null>(null);
  const [predefinedServices, setPredefinedServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [customerData, setCustomerData] = useState<CustomerData>({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    // signature is optional and will be added after signing
  });

  const [openModal, setOpenModal] = useState(false);
  const [modalStep, setModalStep] = useState<'form' | 'signature'>('form');
  const [sigPad, setSigPad] = useState<SignatureCanvas | null>(null);

  const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null);
  const [userData, setUserData] = useState<User | null>(null); // Added userData state

  // Address autocomplete state
  const [addressInput, setAddressInput] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([]);
  const [addressLoading, setAddressLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const markQuoteAsViewed = async () => {
      // Check if there's no token (meaning no logged-in user)
      const token = localStorage.getItem('token');
      const impersonationToken = localStorage.getItem('impersonationToken');
      
      // Only proceed if there's no token and no impersonation token
      if (!token && !impersonationToken && quoteId && companyName) {
        try {
          await publicAxios.post(`/api/public/markViewed/${quoteId}/${encodeURIComponent(companyName)}`);
        } catch (error) {
          console.error('Error marking quote as viewed:', error);
        }
      }
    };
  
    markQuoteAsViewed();
  }, [quoteId, companyName]);
  
  // Function to fetch suggestions from the backend (using the Search API)
  const fetchSuggestions = async (query: string): Promise<any[]> => {
    try {
      const response = await axiosInstance.get('/api/locationiq/search', {
        params: { q: query },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      return [];
    }
  };

  // Debounced fetchSuggestions function
  const debouncedFetchSuggestions = useRef(
    debounce(async (input: string) => {
      if (input.length < 3) {
        setAddressSuggestions([]);
        return;
      }
      setAddressLoading(true);
      const suggestions = await fetchSuggestions(input);
      setAddressSuggestions(suggestions);
      setAddressLoading(false);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedFetchSuggestions.cancel();
    };
  }, [debouncedFetchSuggestions]);

  useEffect(() => {
    if (!quoteId || !companyName) {
      setError('Quote ID or Company Name is missing.');
      setLoading(false);
      return;
    }

    fetchCompanySettings();
    fetchQuoteAndTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId, companyName]);

  // Fetch company settings
  const fetchCompanySettings = async () => {
    try {
      const response = await publicAxios.get(`/api/public/compSettings/${encodeURIComponent(companyName!)}`);
      setCompanySettings(response.data);
    } catch (error) {
      console.error('Error fetching company settings:', error);
      enqueueSnackbar('Failed to fetch company settings.', { variant: 'error' });
    }
  };

  // Fetch quote and template
  const fetchQuoteAndTemplate = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await publicAxios.get(`/api/public/quote/${quoteId}/${encodeURIComponent(companyName!)}`);
      setQuote(response.data.quote);
      setTemplate(response.data.template);

      if (response.data.template.predefinedServiceIds.length > 0) {
        const predefinedServicesResponse = await publicAxios.get(`/api/public/predefinedServices/${encodeURIComponent(companyName!)}`);
        setPredefinedServices(predefinedServicesResponse.data);
      }

      // If the quote is accepted, fetch customer data
      if (response.data.quote.status === 'Accepted') {
        await fetchCustomerData(quoteId!, companyName!);
      }

      // Fetch user data (whoIAmText)
      await fetchUserData(quoteId!, companyName!);
    } catch (err) {
      console.error('Error fetching quote and template:', err);
      setError('Failed to load quote information. Please try again.');
      enqueueSnackbar('Failed to load quote information.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch customer data
  const fetchCustomerData = async (quoteId: string, companyName: string) => {
    try {
      const response = await publicAxios.get(
        `/api/public/customerByQuote/${quoteId}?companyName=${encodeURIComponent(companyName)}`
      );
      setCustomerData(response.data);
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setError('Failed to load customer information.');
      enqueueSnackbar('Failed to load customer information.', { variant: 'error' });
    }
  };

  // Function to fetch user data (whoIAmText)
  const fetchUserData = async (quoteId: string, companyName: string) => {
    try {
      const response = await publicAxios.get(`/api/public/userByQuote/${quoteId}/${encodeURIComponent(companyName)}`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle error or set userData to null
    }
  };

// Updated getServiceDetails function to handle displayName
const getServiceDetails = (serviceName: string): { service: Service; isPredefined: boolean } | undefined => {
  // First, attempt to find the predefined service by displayName
  const predefinedServiceByDisplayName = predefinedServices.find(s => s.displayName === serviceName);
  if (predefinedServiceByDisplayName) {
    return { service: predefinedServiceByDisplayName, isPredefined: true };
  }

  // If not found, attempt to find the predefined service by name
  const predefinedServiceByName = predefinedServices.find(s => s.name === serviceName);
  if (predefinedServiceByName) {
    return { service: predefinedServiceByName, isPredefined: true };
  }

  // If not found in predefined, attempt to find in custom services by name
  const customService = template?.customServices.find(s => s.name === serviceName);
  if (customService) {
    return { service: customService, isPredefined: false };
  }

  // Service not found
  console.warn(`Service "${serviceName}" not found in predefined or custom services.`);
  return undefined;
};

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSignatureEnd = () => {
    if (sigPad) {
      const signatureDataURL = sigPad.getTrimmedCanvas().toDataURL('image/png');
      // Update customerData.signature
      setCustomerData((prev) => ({ ...prev, signature: signatureDataURL }));
    }
  };

  const clearSignature = () => {
    if (sigPad) {
      sigPad.clear();
      // Clear the signature from customerData
      setCustomerData((prev) => ({ ...prev, signature: undefined }));
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setModalStep('form');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalStep('form');
  };

  const handleSubmitForm = () => {
    setModalStep('signature');
  };

  const handleAccept = async () => {
    if (!customerData.signature || !quote || !template || !companyName) {
      setError('Missing required data for quote acceptance. Please try again.');
      enqueueSnackbar('Missing required data for quote acceptance.', { variant: 'error' });
      return;
    }

    if (!quoteId) {
      setError('Quote ID is missing.');
      enqueueSnackbar('Quote ID is missing.', { variant: 'error' });
      return;
    }

    try {
      const acceptedTime = new Date().toISOString();
      const response = await publicAxios.post('/api/public/accept', {
        quoteId,
        customerData,
        companyName,
        acceptedTime,
      });

      // Update the quote state with the response
      setQuote(response.data);

      // Fetch the customer data after acceptance
      await fetchCustomerData(quoteId, companyName);

      enqueueSnackbar('Quote accepted successfully!', { variant: 'success' });

      handleCloseModal();
    } catch (error) {
      console.error('Error accepting quote:', error);
      setError('Failed to accept quote. Please try again.');
      enqueueSnackbar('Failed to accept quote. Please try again.', { variant: 'error' });
    }
  };

  const isFormComplete =
    customerData.firstName.trim() !== '' &&
    customerData.lastName.trim() !== '' &&
    customerData.address.trim() !== '' &&
    customerData.city.trim() !== '' &&
    customerData.state.trim() !== '' &&
    customerData.zip.trim() !== '' &&
    customerData.email.trim() !== '' &&
    customerData.phone.trim() !== '';

   
  const handleDownloadPDF = async () => {
    if (!quote || !template || !customerData.signature) return;
    try {

      // Determine the viewport ratio
      const viewportRatio = window.devicePixelRatio || 1;

      const companyLogoUrl = companySettings?.companyLogo
        ? `${companySettings.companyLogo.replace(/^\//, '')}`
        : undefined;
      console.log('logo url -', companyLogoUrl);
      const pdfBlob = await generateQuotePDF(quote, template, customerData, viewportRatio, companyLogoUrl, predefinedServices);
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quote_${quote.quoteId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      enqueueSnackbar('PDF downloaded successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF. Please try again.');
      enqueueSnackbar('Failed to generate PDF. Please try again.', { variant: 'error' });
    }
  };

  // Update the handleSendEmail function in CustomerQuoteLanding.tsx 
  const handleSendEmail = async () => {
    if (!quote || !template || !customerData.signature || !customerData.email) return;
    
    try {

      // Determine the viewport ratio
      const viewportRatio = window.devicePixelRatio || 1;
      
      // First generate the PDF
      const companyLogoUrl = companySettings?.companyLogo
        ? `${process.env.REACT_APP_API_URL}${companySettings.companyLogo.replace(/^\//, '')}`
        : undefined;
      
      const pdfBlob = await generateQuotePDF(quote, template, customerData, viewportRatio, companyLogoUrl, predefinedServices);
      
      // Create FormData object to send the PDF
      const formData = new FormData();
      formData.append('pdfQuote', pdfBlob, 'quote.pdf');
      formData.append('to', customerData.email);
      formData.append('subject', `Your Quote from ${template.companyName}`);
      
      // Create HTML body with styling
      const emailBody = `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
          <h2 style="color: ${template.customization.primaryColor};">Thank you for accepting your quote!</h2>
          
          <p>Dear ${customerData.firstName} ${customerData.lastName},</p>
          
          <p>Thank you for accepting our quote for the following services:</p>
          <ul>
            ${quote.services.map((service, index) => `
              <li style="margin-bottom: 10px;">
                ${service}: $${quote.serviceValues[index]}
              </li>
            `).join('')}
          </ul>
          
          <p style="font-weight: bold;">Total Amount: $${quote.totalValue.toFixed(2)}</p>
          
          <p>We'll be in contact within 24 hours to discuss scheduling and next steps.</p>
          
          <p>Your signed quote is attached to this email for your records.</p>
          
          <div style="margin-top: 20px; padding-top: 20px; border-top: 1px solid #eee;">
            <p style="color: ${template.customization.secondaryColor};">Best regards,<br>${template.companyName}</p>
            ${companySettings?.companyPhoneNumber ? `<p>Phone: ${companySettings.companyPhoneNumber}</p>` : ''}
            ${companySettings?.websiteLink ? `<p>Website: <a href="${companySettings.websiteLink}">${companySettings.websiteLink}</a></p>` : ''}
          </div>
        </div>
      `;
      
      formData.append('html', emailBody);

      // Send email using SendGrid
      await publicAxios.post('/api/email/send-quote-confirmation', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      enqueueSnackbar('Email sent successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Failed to send email. Please try again.');
      enqueueSnackbar('Failed to send email. Please try again.', { variant: 'error' });
    }
  };

  const getLandingPageIntro = () => {
    if (!template) return '';
    if (template.landingPageIntro && template.landingPageIntro.trim() !== '') {
      return template.landingPageIntro;
    } else {
      const daysToExpire = template.daysToExpire || 30;
      return `Thanks for scanning your instant quote! We service some of your neighbors and thought we’d give you a quote. This quote expires in ${daysToExpire} days, so no pressure!`;
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!quote || !template) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>Quote not found.</Typography>
      </Box>
    );
  }
  
  // Calculate days until expiration
  const getDaysUntilExpiration = () => {
    if (!quote || !quote.expirationTime) return null;
    const expirationDate = new Date(quote.expirationTime);
    const currentDate = new Date();
    const timeDiff = expirationDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff > 0 ? daysDiff : 0;
  };

  const daysUntilExpiration = getDaysUntilExpiration();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        margin: 0,
        padding: 0,
        fontFamily: template.customization.fontFamily,
      }}
    >
      {/* Main content */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
          padding: { xs: 3, sm: 4 },
        }}
      >
        {/* Header with Cowboy Hat logo */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CowboyHatLogo />
        </Box>

        {/* Grid container for layout */}
        <Grid container spacing={2}>
          {isMobile ? (
            <>
              <Grid item xs={12}>
                {/* Left side content */}
                <Typography variant='h3'>
                  Hi there!
                </Typography>
                {getLandingPageIntro() && (
                  <Typography variant="body1" paragraph>
                    {getLandingPageIntro()}
                  </Typography>
                )}

                <Typography variant='h4' sx={{ mt: 4, mb: 2 }}>
                  Your Instant Quote:
                </Typography>

                {/* The Quote */}
                <Paper
                  elevation={3}
                  sx={{
                    padding: { xs: 2, sm: 3 },
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    mb: 4,
                    bgcolor: '#ffffff',
                    color: '#1E1F20',
                  }}
                >
                  {quote.status === 'Accepted' && (
                    <Chip
                      label="Accepted"
                      color="success"
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: -15,
                        transform: 'rotate(45deg)',
                      }}
                    />
                  )}
                  {/* Expires in X days label */}
                  {daysUntilExpiration !== null && quote.status !== 'Accepted' && (
                    <Chip
                      label={`Expires in ${daysUntilExpiration} Days`}
                      color="warning"
                      sx={{
                        position: 'absolute',
                        top: 30,
                        right: -20,
                        transform: 'rotate(45deg)',
                      }}
                    />
                  )}
                  <Box sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {companySettings?.companyLogo && (
                        <Box
                          sx={{
                            mt: 4,
                            mb: 3,
                            maxWidth: '100%',
                            height: '80px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={`${companySettings.companyLogo.replace(/^\//, '')}`}
                            alt="Company Logo"
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                          />
                        </Box>
                      )}
                    </Box>
                   {/* Template Name */}
                    {template.customization.showTitle && (
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ color: template.customization.primaryColor }}
                    >
                      {template.name}
                    </Typography>
                    )}
                    {template.customization.showIntro && (
                      <Typography variant="body1" paragraph>
                        {template.introParagraph}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                    {quote.services.map((serviceName, index) => {
                        // Get the service value from the serviceValues array
                        const serviceValue = quote.serviceValues[index];
                        
                        // Skip rendering if value is empty or 0
                        if (serviceValue === '' || parseFloat(serviceValue) === 0) return null;

                        // Rest of your existing service rendering code
                        const serviceInfo = getServiceDetails(serviceName);
                        return (
                          serviceInfo && (
                            <Grid item xs={12} key={`${serviceName}-${index}`}>
                              {/* Grid Container for Service Name and Value */}
                              <Grid container alignItems="center" sx={{ mb: 1 }}>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: template.customization.secondaryColor }}
                                  >
                                    {serviceInfo.service.displayName || serviceInfo.service.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <Typography variant="body1" sx={{ color: '#1E1F20' }}>
                                    ${serviceValue}
                                  </Typography>
                                </Grid>
                              </Grid>

                              {/* Service Description - will also be hidden */}
                              <ServiceDescription>
                                {parse(
                                  DOMPurify.sanitize(
                                    serviceInfo.isPredefined 
                                      ? (serviceInfo.service.quotePageDesc || serviceInfo.service.description)
                                      : serviceInfo.service.description
                                  )
                                )}
                              </ServiceDescription>
                            </Grid>
                          )
                        );
                      })}
                    </Grid>
                  </Box>

                  <Box sx={{ mt: 'auto', pt: 4 }}>
                    <Typography variant="h5" align="right" sx={{ marginBottom: 2, color: '#1E1F20' }}>
                      Total: ${quote.totalValue.toFixed(2)}
                    </Typography>

                    {quote.status === 'Accepted' && customerData.signature && (
                      <Box sx={{ textAlign: 'right', mt: 2, mb: 2 }}>
                        <img
                          src={customerData.signature}
                          alt="Signature"
                          style={{ maxWidth: '200px', maxHeight: '50px' }}
                        />
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1.2, color: 'black' }}>
                          Signed by {customerData.firstName} {customerData.lastName}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1.2, color: 'black' }}>
                          {new Date(quote.acceptedTime!).toLocaleString()}
                        </Typography>
                      </Box>
                    )}

                    <Typography variant="h6" gutterBottom sx={{ color: '#1E1F20' }}>
                      Terms and Conditions:
                    </Typography>
                    {quote.services.map((serviceName) => {
                      const serviceInfo = getServiceDetails(serviceName);
                      return serviceInfo?.service.fineText ? (
                        <Typography key={serviceName} variant="body2" paragraph sx={{ color: '#1E1F20', fontSize: 11 }}>
                          {/* <strong>{serviceName}:</strong> */} {parse(DOMPurify.sanitize(serviceInfo.service.fineText))}
                        </Typography>
                      ) : null;
                    })}

                    {quote.status !== 'Accepted' && quote.totalValue !== 0 && (
                      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          onClick={handleOpenModal}
                          sx={{
                            backgroundColor: template.customization.primaryColor,
                            color: 'white',
                            '&:hover': {
                              backgroundColor: lighten(template.customization.primaryColor, 0.2),
                            },
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          Enter Information and Sign Electronically
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>

                {/* Who We Are Section */}
                {companySettings?.whoWeAreText && (
              <>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Who We Are:
                </Typography>
                <Typography variant="body1" paragraph>
                  {companySettings.whoWeAreText}
                </Typography>
              </>
            )}

                {companySettings?.websiteLink && (
                  <Button
                    onClick={() => window.open(companySettings.websiteLink, '_blank')}
                    sx={{
                      backgroundColor: template.customization.primaryColor,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: lighten(template.customization.primaryColor, 0.2),
                      },
                      mb: 2,
                    }}
                  >
                    Visit our website
                  </Button>
                )}

                {companySettings?.googleMapEmbed && (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                    dangerouslySetInnerHTML={{ __html: companySettings.googleMapEmbed }}
                  />
                )}

                {/* Who I Am Section */}
                {userData?.whoIAmText && (
                  <>
                <Typography variant='h4' sx={{ mt: 2 }}>
                  Who I Am:
                </Typography>
                
                  <Typography variant="body1" paragraph>
                    {userData.whoIAmText}
                  </Typography>
                  </>
                )}

                {/* Video Section */}
                {quote.videoPath && (
                  <Box sx={{ mt: 2 }}>
                    <video width="100%" controls>
                      <source
                        src={quote.videoPath}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                {/* Mobile Footer */}
                {/* You can add mobile-specific footer content here */}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                {/* Left side content */}
                <Typography variant='h3'>
                  Hi there!
                </Typography>
                {getLandingPageIntro() && (
                  <Typography variant="body1" paragraph>
                    {getLandingPageIntro()}
                  </Typography>
                )}
                 {companySettings?.whoWeAreText && (
                  <>
                <Typography variant='h4' sx={{ mt: 2 }}>
                  Who We Are:
                </Typography>
               
                  <Typography variant="body1" paragraph>
                    {companySettings.whoWeAreText}
                  </Typography>
                  </>
                )}

                {companySettings?.websiteLink && (
                  <Button
                    onClick={() => window.open(companySettings.websiteLink, '_blank')}
                    sx={{
                      backgroundColor: template.customization.primaryColor,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: lighten(template.customization.primaryColor, 0.2),
                      },
                    }}
                  >
                    Visit our website
                  </Button>
                )}

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    {companySettings?.googleMapEmbed && (
                      <Box
                        sx={{
                          mt: 2,
                          mb: 2,
                          position: 'relative',
                          overflow: 'hidden',
                          paddingTop: '56.25%', // 16:9 aspect ratio
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          dangerouslySetInnerHTML={{ __html: companySettings.googleMapEmbed }}
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {quote.videoPath && (
                      <Box
                        sx={{
                          mt: 2,
                          mb: 2,
                          position: 'relative',
                          overflow: 'hidden',
                          paddingTop: '56.25%', // 16:9 aspect ratio
                        }}
                      >
                        <video
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          controls
                        >
                          <source
                            src={quote.videoPath}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </Box>
                    )}
                  </Grid>
                </Grid>

                {/* Who I Am Section */}
                {userData?.whoIAmText && (
                  <>
                <Typography variant='h4' sx={{ mt: 2 }}>
                  Who I Am:
                </Typography>
                
                  <Typography variant="body1" paragraph>
                    {userData.whoIAmText}
                  </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Right side content: The rendered quote */}
                <Paper
                  elevation={3}
                  sx={{
                    padding: { xs: 2, sm: 3 },
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    width: '80%',
                    ml: 10,
                    bgcolor: '#ffffff',
                    color: '#1E1F20',
                  }}
                >
                  {quote.status === 'Accepted' && (
                    <Chip
                      label="Accepted"
                      color="success"
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: -15,
                        transform: 'rotate(45deg)',
                      }}
                    />
                  )}
                  {/* Expires in X days label */}
                  {daysUntilExpiration !== null && quote.status !== 'Accepted' && (
                    <Chip
                      label={`Expires in ${daysUntilExpiration} Days`}
                      color="warning"
                      sx={{
                        position: 'absolute',
                        top: 20,
                        left: -25,
                        transform: 'rotate(-45deg)',
                      }}
                    />
                  )}
                  <Box sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {companySettings?.companyLogo && (
                        <Box
                          sx={{
                            mt: 4,
                            mb: 3,
                            maxWidth: '100%',
                            height: '80px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={`${companySettings.companyLogo.replace(/^\//, '')}`}
                            alt="Company Logo"
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                          />
                        </Box>
                      )}
                    </Box>
                    {template.customization.showTitle && (
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ color: template.customization.primaryColor }}
                    >
                      {template.name}
                    </Typography>
                    )}
                    {template.customization.showIntro && (
                      <Typography variant="body1" paragraph>
                        {template.introParagraph}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                    {quote.services.map((serviceName, index) => {
                        // Get the service value from the serviceValues array
                        const serviceValue = quote.serviceValues[index];
                        
                        // Skip rendering if value is empty or 0
                        if (serviceValue === '' || parseFloat(serviceValue) === 0) return null;

                        // Rest of your existing service rendering code
                        const serviceInfo = getServiceDetails(serviceName);
                        return (
                          serviceInfo && (
                            <Grid item xs={12} key={`${serviceName}-${index}`}>
                              {/* Grid Container for Service Name and Value */}
                              <Grid container alignItems="center" sx={{ mb: 1 }}>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: template.customization.secondaryColor }}
                                  >
                                    {serviceInfo.service.displayName || serviceInfo.service.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <Typography variant="body1" sx={{ color: '#1E1F20' }}>
                                    ${serviceValue}
                                  </Typography>
                                </Grid>
                              </Grid>

                              {/* Service Description - will also be hidden */}
                              <ServiceDescription>
                                {parse(
                                  DOMPurify.sanitize(
                                    serviceInfo.isPredefined 
                                      ? (serviceInfo.service.quotePageDesc || serviceInfo.service.description)
                                      : serviceInfo.service.description
                                  )
                                )}
                              </ServiceDescription>
                            </Grid>
                          )
                        );
                      })}
                    </Grid>
                  </Box>

                  <Box sx={{ mt: 'auto', pt: 4 }}>
                    <Typography variant="h5" align="right" sx={{ marginBottom: 2, color: '#1E1F20' }}>
                      Total: ${quote.totalValue.toFixed(2)}
                    </Typography>

                    {quote.status === 'Accepted' && customerData.signature && (
                      <Box sx={{ textAlign: 'right', mt: 2, mb: 2 }}>
                        <img
                          src={customerData.signature}
                          alt="Signature"
                          style={{ maxWidth: '200px', maxHeight: '50px' }}
                        />
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1.2, color: 'black' }}>
                          Signed by {customerData.firstName} {customerData.lastName}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1.2, color: 'black' }}>
                          {new Date(quote.acceptedTime!).toLocaleString()}
                        </Typography>
                      </Box>
                    )}

                    <Typography variant="h6" gutterBottom sx={{ color: '#1E1F20' }}>
                      Terms and Conditions:
                    </Typography>
                    {quote.services.map((serviceName) => {
                      const serviceInfo = getServiceDetails(serviceName);
                      return serviceInfo?.service.fineText ? (
                        <Typography key={serviceName} variant="body2" paragraph sx={{ color: '#1E1F20',fontSize: 11 }}>
                        {/*   <strong>{serviceName}:</strong> */} {parse(DOMPurify.sanitize(serviceInfo.service.fineText))}
                        </Typography>
                      ) : null;
                    })}

                    {quote.status !== 'Accepted' && quote.totalValue !== 0 && (
                      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          onClick={handleOpenModal}
                          sx={{
                            backgroundColor: template.customization.primaryColor,
                            color: 'white',
                            '&:hover': {
                              backgroundColor: lighten(template.customization.primaryColor, 0.2),
                            },
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          Enter Information and Sign Electronically
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>

        {quote.status === 'Accepted' && (
          <Box
          sx={{
            // Apply a semi-transparent background color using the alpha utility
            backgroundColor: alpha(theme.palette.warning.main, 0.2),
            position: 'fixed',
            right: 0, // Extend background to the right edge
            top: '82%',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingRight: 2, // 20px (assuming default theme spacing of 8px)
            paddingLeft: 2, // Optional: Add left padding if needed
            paddingTop: 2, // Optional: Add top padding if needed
            paddingBottom: 2, // Optional: Add bottom padding if needed
            borderBottom: 3,
            borderColor: theme.palette.warning.main,
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            // Optional: Add shadow or other styles as needed
            boxShadow: theme.shadows[4],
            // Ensure the Box doesn't exceed the viewport height
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
          >
            <Tooltip title="Download PDF">
              <IconButton onClick={handleDownloadPDF}  sx={{color: 'warning.main'}}>
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Email">
              <IconButton onClick={handleSendEmail} sx={{color: 'warning.main'}}>
                <Email />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Footer />

      {/* Modal for entering information and signing */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="customer-info-modal"
        aria-describedby="customer-info-and-signature"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 500 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {modalStep === 'form' ? (
            <>
              <Typography variant="h6" component="h2" gutterBottom>
                Customer Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={customerData.firstName}
                    onChange={handleInputChange}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={customerData.lastName}
                    onChange={handleInputChange}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressDropdown
                    value={addressInput}
                    
                    suggestions={addressSuggestions}
                    onChange={(newInputValue) => {
                      setAddressInput(newInputValue);
                      debouncedFetchSuggestions(newInputValue);
                    }}
                    
                    onSelect={(suggestion) => {
                      const addressComponents = suggestion.address;
                      setCustomerData((prev) => ({
                        ...prev,
                        address:
                          `${addressComponents.house_number || ''} ${
                            addressComponents.road ||
                            addressComponents.street ||
                            addressComponents.neighbourhood ||
                            ''
                          }`.trim() || suggestion.display_name || '',
                        city:
                          addressComponents.city ||
                          addressComponents.town ||
                          addressComponents.village ||
                          addressComponents.hamlet ||
                          '',
                        state: addressComponents.state || '',
                        zip: addressComponents.postcode || '',
                      }));
                      setAddressInput(suggestion.display_name || '');
                    }}
                    loading={addressLoading}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={customerData.city}
                    onChange={handleInputChange}
                    required
                    disabled
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={customerData.state}
                    onChange={handleInputChange}
                    required
                    disabled
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="ZIP"
                    name="zip"
                    value={customerData.zip}
                    onChange={handleInputChange}
                    required
                    disabled
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={customerData.email}
                    onChange={handleInputChange}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={customerData.phone}
                    onChange={handleInputChange}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmitForm}
                  disabled={!isFormComplete}
                  color='warning'
                >
                  Submit information and sign
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h6" component="h2" gutterBottom>
                Sign to Accept Quote
              </Typography>
              <SignatureCanvas
                ref={(ref) => setSigPad(ref)}
                canvasProps={{ width: 350, height: 200, className: 'signature-canvas' }}
                onEnd={handleSignatureEnd}
                backgroundColor="rgba(255,255,255,0)"
                penColor="black"
              />
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button 
                  color='info'
                  variant='contained'
                  sx={{ mr: 1 }}
                  onClick={clearSignature}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAccept}
                  color='warning'
                  disabled={!customerData.signature}
                >
                  Accept quote
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomerQuoteLanding;