// src/components/TrailGuide/NavigationTabs.tsx

import React from 'react';
import { Tabs, Tab, alpha, useTheme, useMediaQuery } from '@mui/material';
import {
  VideoLibrary,
  QuestionAnswer,
  Article,
  LibraryBooks,
} from '@mui/icons-material';

interface NavigationTabsProps {
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ currentTab, handleTabChange }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Tabs
      value={currentTab}
      onChange={handleTabChange}
      variant={isSmallScreen ? 'scrollable' : 'fullWidth'}
      scrollButtons={isSmallScreen ? 'auto' : false}
      allowScrollButtonsMobile
      aria-label="Trail Guide Navigation Tabs"
      sx={{
        mb: 4,
        '& .MuiTabs-indicator': {
          backgroundColor: 'warning.main',
        },
      }}
    >
      {/* Removed the 'All Guides' Tab */}
      {/*
      <Tab
        icon={<VideoLibrary />}
        label="How-To's"
        iconPosition="start"
        sx={(theme) => ({
          color: 'warning.main',
          '&.Mui-selected': {
            color: 'warning.main',
          },
          '&:hover': {
            color: alpha(theme.palette.common.white, 1),
            backgroundColor: alpha(theme.palette.warning.main, 0.8),
            cursor: 'pointer',
          },
        })}
      /> */}
      <Tab
        icon={<QuestionAnswer />}
        label="FAQs"
        iconPosition="start"
        sx={(theme) => ({
          color: 'warning.main',
          '&.Mui-selected': {
            color: 'warning.main',
          },
          '&:hover': {
            color: alpha(theme.palette.common.white, 1),
            backgroundColor: alpha(theme.palette.warning.main, 0.8),
            cursor: 'pointer',
          },
        })}
      />
      <Tab
        icon={<Article />}
        label="Announcements"
        iconPosition="start"
        sx={(theme) => ({
          color: 'warning.main',
          '&.Mui-selected': {
            color: 'warning.main',
          },
          '&:hover': {
            color: alpha(theme.palette.common.white, 1),
            backgroundColor: alpha(theme.palette.warning.main, 0.8),
            cursor: 'pointer',
          },
        })}
      />
      <Tab
        icon={<LibraryBooks />}
        label="Articles"
        iconPosition="start"
        sx={(theme) => ({
          color: 'warning.main',
          '&.Mui-selected': {
            color: 'warning.main',
          },
          '&:hover': {
            color: alpha(theme.palette.common.white, 1),
            backgroundColor: alpha(theme.palette.warning.main, 0.8),
            cursor: 'pointer',
          },
        })}
      />
    </Tabs>
  );
};

export default NavigationTabs;