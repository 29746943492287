// frontend/src/components/AdminDashboard/ViewCompany.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import adminAxiosInstance from '../utils/adminAxiosConfig';
import {
  Container,
  Typography,
  CircularProgress,
  Paper,
  Box,
  Grid,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TablePagination,
} from '@mui/material';
import {
  Business as BusinessIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
interface Plan {
  name: string;
}

interface Company {
  _id: string;
  companyName: string;
  cleanedCompanyName: string;
  planTier: Plan;
  createdAt: string;
}

interface CompSettings {
  companyName: string;
  subscriptionStatus: string;
  usage: {
    quotesGenerated: number;
    month: number;
  };
  companyLogo?: string;
  companyPhoneNumber?: string;
  websiteLink?: string;
  whoWeAreText?: string;
  // Add other fields as needed
}

interface UsageStatistics {
  totalQuotes: number;
  acceptedQuotes: number;
  rejectedQuotes: number;
  activeQuotes: number;
}

interface Quote {
  _id: string;
  quoteId: string;
  customerName: string;
  totalValue: number;
  status: string;
  time: string;
}

interface User {
  _id: string;
  name: string;
  email: string;
  role: string;
  createdAt: string;
}

const ViewCompany: React.FC = () => {
    const { companyId } = useParams();
    const navigate = useNavigate();
    const [company, setCompany] = useState<Company | null>(null);
    const [compSettings, setCompSettings] = useState<CompSettings | null>(null);
    const [usageStatistics, setUsageStatistics] = useState<UsageStatistics | null>(null);
    const [recentQuotes, setRecentQuotes] = useState<Quote[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
  
    // Add state for time range
    const [timeRange, setTimeRange] = useState<string>('30'); // Default to past 30 days
  
    // Add state for pagination
    const [currentPage, setCurrentPage] = useState<number>(1);
    const quotesPerPage = 5;
  
    useEffect(() => {
      const fetchCompanyDetails = async () => {
        try {
          setLoading(true); // Move setLoading here to handle loading state on time range change
          const response = await adminAxiosInstance.get(
            `/api/admin/companies/${companyId}?timeRange=${timeRange}`
          );
          const {
            company,
            compSettings,
            usageStatistics,
            recentQuotes,
            users,
          } = response.data;
  
          setCompany({
            ...company,
            createdAt: new Date(company.createdAt).toLocaleDateString(),
          });
          setCompSettings(compSettings);
          setUsageStatistics(usageStatistics);
          setRecentQuotes(
            recentQuotes.map((quote: Quote) => ({
              ...quote,
              time: new Date(quote.time).toLocaleDateString(),
            }))
          );
          setUsers(
            users.map((user: User) => ({
              ...user,
              createdAt: new Date(user.createdAt).toLocaleDateString(),
            }))
          );
          setCurrentPage(1); // Reset to first page whenever data changes
        } catch (error) {
          console.error('Error fetching company details:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchCompanyDetails();
    }, [companyId, timeRange]); // Add timeRange as a dependency
  
 // Handler for time range change
const handleTimeRangeChange = (event: SelectChangeEvent) => {
    setTimeRange(event.target.value as string);
  };
  
    // Calculate pagination
    const indexOfLastQuote = currentPage * quotesPerPage;
    const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
    const currentQuotes = recentQuotes.slice(indexOfFirstQuote, indexOfLastQuote);
    const totalPages = Math.ceil(recentQuotes.length / quotesPerPage);
  
    // Handler for page change
    const handlePageChange = (newPage: number) => {
      setCurrentPage(newPage);
    };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!company || !compSettings || !usageStatistics) {
    return (
      <Container>
        <Typography variant="h6">Company not found</Typography>
      </Container>
    );
  }

  const handleImpersonate = async () => {
    try {
      const response = await adminAxiosInstance.post(`/api/admin/impersonate/${companyId}`);
      const { token, user } = response.data;

      if (!token || !user) {
        throw new Error('Invalid response from impersonation API');
      }

      // Store the impersonation token
      localStorage.setItem('impersonationToken', token);

      // Store the user data as 'userData' in localStorage
      localStorage.setItem('userData', JSON.stringify(user));


      // Redirect to the company's dashboard
      window.location.href = '/dashboard'; // Adjust the path if necessary
    } catch (error: any) {
      console.error('Error during impersonation:', error.response?.data || error.message);
      // Optionally, display an error message to the user
    }
  };

  return (
    <Container maxWidth="lg">
      <Box mt={2}>
        <Button
          variant="outlined"
          color='warning'
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Back to Dashboard
        </Button>
      </Box>

      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        {/* Company Information */}
        <Box mb={3}>
          <Typography variant="h4" gutterBottom>
            {company.companyName}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">
                <strong>Subscription Status:</strong> {compSettings.subscriptionStatus}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Plan Tier:</strong> {company.planTier?.name || 'N/A'}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Sign-Up Date:</strong> {company.createdAt}
              </Typography>
              {compSettings.websiteLink && (
                <Typography variant="subtitle1">
                  <strong>Website:</strong>{' '}
                  <a href={compSettings.websiteLink} target="_blank" rel="noopener noreferrer">
                    {compSettings.websiteLink}
                  </a>
                </Typography>
              )}
              {compSettings.companyPhoneNumber && (
                <Typography variant="subtitle1">
                  <strong>Phone:</strong> {compSettings.companyPhoneNumber}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {compSettings.companyLogo && (
                <img
                  src={compSettings.companyLogo}
                  alt={`${company.companyName} Logo`}
                  style={{ maxWidth: '200px' }}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider />

       {/* Usage Statistics */}
<Box mt={3} mb={3}>
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h5" gutterBottom>
      Usage Statistics
    </Typography>
    <FormControl variant="outlined" size="small">
      <InputLabel sx={{
                    '&.Mui-focused': {
                        color: 'warning.main',
                    },
                    }} >Time Range</InputLabel>
      <Select
        value={timeRange}
        onChange={handleTimeRangeChange}
        label="Time Range"
        sx={{
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'warning.main',
            },
            }}
      >
        <MenuItem value="30">Past 30 Days</MenuItem>
        <MenuItem value="90">Past 90 Days</MenuItem>
        <MenuItem value="180">Past 6 Months</MenuItem>
        <MenuItem value="365">Past Year</MenuItem>
        <MenuItem value="all">All Time</MenuItem>
      </Select>
    </FormControl>
  </Box>
  <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <Paper elevation={1} style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Total Quotes</Typography>
                <Typography variant="h4">{usageStatistics.totalQuotes}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper elevation={1} style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Accepted Quotes</Typography>
                <Typography variant="h4">{usageStatistics.acceptedQuotes}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper elevation={1} style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Rejected Quotes</Typography>
                <Typography variant="h4">{usageStatistics.rejectedQuotes}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper elevation={1} style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Active Quotes</Typography>
                <Typography variant="h4">{usageStatistics.activeQuotes}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Divider />

        {/* Users */}
        <Box mt={3} mb={3}>
          <Typography variant="h5" gutterBottom>
            Users
          </Typography>
          <List>
            {users.map((user) => (
              <ListItem key={user._id}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.name}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {user.email}
                      </Typography>
                      {' — '}
                      {user.role}
                      {' — Joined on '}
                      {user.createdAt}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider />

      {/* Recent Quotes */}
<Box mt={3} mb={3}>
  <Typography variant="h5" gutterBottom>
    Recent Quotes
  </Typography>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Quote ID</TableCell>
        <TableCell>Customer Name</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Total Value</TableCell>
        <TableCell>Date Created</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {currentQuotes.map((quote) => (
        <TableRow key={quote._id}>
          <TableCell>{quote.quoteId}</TableCell>
          <TableCell>{quote.customerName || 'N/A'}</TableCell>
          <TableCell>{quote.status}</TableCell>
          <TableCell>${quote.totalValue.toFixed(2)}</TableCell>
          <TableCell>{quote.time}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  <Box display="flex" justifyContent="center" mt={2}>
    <Button
      variant="outlined"
      color="warning"
      disabled={currentPage === 1}
      onClick={() => handlePageChange(currentPage - 1)}
      sx={{width:80}}
    >
      Previous
    </Button>
    <Typography variant="body1" style={{ margin: '0 10px', alignSelf: 'center' }}>
      Page {currentPage} of {totalPages}
    </Typography>
    <Button
      variant="outlined"
      color="warning"
      disabled={currentPage === totalPages}
      onClick={() => handlePageChange(currentPage + 1)}
      sx={{width:80}}
    >
      Next
    </Button>
  </Box>
</Box>
{/* Impersonate Button */}
<Box mt={4} textAlign="center">
  <Button
    variant="contained"
    color="warning"
    startIcon={<VisibilityIcon />}
    onClick={handleImpersonate}
  >
    View App As {company.companyName}
  </Button>
</Box>
      </Paper>
    </Container>
  );
};

export default ViewCompany;