// components/campaigns/CreateCampaignModal.tsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  Link,
} from '@mui/material';
import { Upload, Download, CheckCircleOutline, ErrorOutline, Cancel } from '@mui/icons-material';
import axiosInstance from '../../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';

interface Template {
  templateId: string;
  name: string;
}

interface CreateCampaignModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [name, setName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [failedGeocodes, setFailedGeocodes] = useState<any[]>([]);
  const [showFailedList, setShowFailedList] = useState(false);
  const [csvPreview, setCsvPreview] = useState<{ headers: string[]; sampleRows: string[][] } | null>(null);
  const [missingHeaders, setMissingHeaders] = useState<string[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        Papa.parse(file, {
          header: true,
          preview: 5,
          complete: (results) => {
            const headers = results.meta.fields || [];
            const requiredHeaders = ['Client Name', 'Address', 'City', 'State', 'Zip'];
            const missing = requiredHeaders.filter(h => !headers.includes(h));
            
            setMissingHeaders(missing);
            setCsvPreview({
              headers: headers || [],
              sampleRows: results.data.slice(0, 5).map((row: any) => 
                requiredHeaders.map(h => row[h]?.toString() || '')
              )
            });

            if (missing.length > 0) {
              enqueueSnackbar(`Missing ${missing.length} required headers`, { variant: 'error' });
              setCsvFile(null);
            } else {
              setCsvFile(file);
              enqueueSnackbar('CSV format validated', { variant: 'success' });
            }
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
            enqueueSnackbar('Error parsing CSV file', { variant: 'error' });
            setCsvFile(null);
          }
        });
      }
    }, [enqueueSnackbar]),
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false
  });

  useEffect(() => {
    if (open) {
      fetchTemplates();
    }
  }, [open]);

  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get('/api/quoteTemplates');
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      enqueueSnackbar('Failed to fetch templates', { variant: 'error' });
    }
  };

  const downloadTemplate = () => {
    const csvContent = [
      ['Client Name', 'Address', 'City', 'State', 'Zip'],
      ['Example Client 1', '123 Main St', 'Springfield', 'IL', '62704'],
      ['Example Client 2', '456 Oak Ave', 'Shelbyville', 'KY', '40065']
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'campaign_template.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleSubmit = async () => {
    if (!name || !selectedTemplate || !csvFile) {
      enqueueSnackbar('Please fill in all fields', { variant: 'warning' });
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('templateId', selectedTemplate);
    formData.append('customerCsv', csvFile);

    try {
      setLoading(true);
      const response = await axiosInstance.post('/api/campaigns/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.failedGeocodes?.length > 0) {
        enqueueSnackbar(response.data.message, { variant: 'warning' });
        setFailedGeocodes(response.data.failedGeocodes);
        setShowFailedList(true);
      } else {
        enqueueSnackbar('Campaign created successfully', { variant: 'success' });
      }

      onSuccess();
      handleReset();
    } catch (error) {
      console.error('Error creating campaign:', error);
      enqueueSnackbar('Failed to create campaign', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setSelectedTemplate('');
    setCsvFile(null);
    setFailedGeocodes([]);
    setShowFailedList(false);
    setCsvPreview(null);
    setMissingHeaders([]);
  };

  const handleCloseFailedList = () => {
    setShowFailedList(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ backgroundColor: theme.palette.background.paper }}>
        Create New Campaign
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Campaign Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                borderColor: theme.palette.warning.main,
              },
            }}
          />

          <TextField
            select
            fullWidth
            label="Quote Template"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            margin="normal"
            required
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                borderColor: theme.palette.warning.main,
              },
            }}
          >
            {templates.map((template) => (
              <MenuItem key={template.templateId} value={template.templateId}>
                {template.name}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ mt: 3 }}>
            <Box
              {...getRootProps()}
              sx={{
                border: `2px dashed ${theme.palette.divider}`,
                borderRadius: 2,
                p: 4,
                textAlign: 'center',
                backgroundColor: isDragActive ? theme.palette.action.hover : theme.palette.background.default,
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover
                }
              }}
            >
              <input {...getInputProps()} />
              <Upload fontSize="large" sx={{ color: theme.palette.text.secondary, mb: 1 }} />
              <Typography variant="h6" gutterBottom>
                {isDragActive ? 'Drop CSV here' : 'Drag & Drop CSV or Click to Upload'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Required columns: Client Name, Address, City, State, Zip
              </Typography>

              {csvPreview ? (
                <Box sx={{ mt: 3, textAlign: 'left' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    CSV Preview
                  </Typography>
                  <Box sx={{ 
                    maxHeight: 200, 
                    overflow: 'auto',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1
                  }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{ backgroundColor: theme.palette.background.default }}>
                          {csvPreview.headers.map((header, i) => (
                            <th key={i} style={{ 
                              padding: '8px',
                              borderBottom: `2px solid ${theme.palette.divider}`,
                              textAlign: 'left',
                              color: missingHeaders.includes(header) ? theme.palette.error.main : 'inherit'
                            }}>
                              {header}
                              {missingHeaders.includes(header) && (
                                <Cancel fontSize="small" sx={{ 
                                  color: theme.palette.error.main,
                                  ml: 0.5,
                                  verticalAlign: 'middle'
                                }} />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {csvPreview.sampleRows.map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td key={j} style={{
                                padding: '8px',
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                fontSize: '0.875rem'
                              }}>
                                {cell || <span style={{ color: theme.palette.text.disabled }}>empty</span>}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="text"
                    color="warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadTemplate();
                    }}
                    startIcon={<Download />}
                  >
                    Download Template
                  </Button>
                  <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                    Need help formatting? <Link component="button" onClick={downloadTemplate} sx={{color:'warning.main'}}>Use our template</Link>
                  </Typography>
                </Box>
              )}
            </Box>

            {csvPreview && (
              <Box sx={{ 
                mt: 2,
                p: 2,
                borderRadius: 1,
                backgroundColor: missingHeaders.length > 0 
                  ? theme.palette.error.light 
                  : theme.palette.success.light
              }}>
                <Typography variant="body2" sx={{ 
                  color: missingHeaders.length > 0 
                    ? theme.palette.error.main 
                    : theme.palette.success.dark,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  {missingHeaders.length > 0 ? (
                    <>
                      <ErrorOutline fontSize="small" />
                      Missing {missingHeaders.length} required column(s)
                    </>
                  ) : (
                    <>
                      <CheckCircleOutline fontSize="small" />
                      All required columns present
                    </>
                  )}
                </Typography>
                {missingHeaders.length > 0 && (
                  <Typography variant="caption" sx={{ 
                    display: 'block',
                    mt: 1,
                    color: theme.palette.error.main
                  }}>
                    Required: {missingHeaders.join(', ')}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant = 'contained' color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleReset} variant = 'contained' color="info" disabled={loading}>
          Reset
        </Button>
        <Button
          onClick={handleSubmit}
          color="warning"
          variant="contained"
          disabled={loading || missingHeaders.length > 0}
        >
          {loading ? <CircularProgress size={24} /> : 'Create Campaign'}
        </Button>
      </DialogActions>

      {/* Failed Geocodes Dialog */}
      <Dialog open={showFailedList} onClose={handleCloseFailedList} fullWidth maxWidth="sm">
        <DialogTitle>Failed Geocoding Entries</DialogTitle>
        <DialogContent>
          <List dense>
            {failedGeocodes.map((fail, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Row ${fail.row}: ${fail.address || 'N/A'}`}
                  secondary={fail.reason}
                  primaryTypographyProps={{ variant: 'body2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFailedList} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CreateCampaignModal;