// src/components/TrailGuide/TrailGuide.tsx

import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import SearchBar from './SearchBar';
import NavigationTabs from './NavigationTabs';
import GuideList from './GuideList';
import ArticleCards from './ArticleCards';
import FAQList from './FAQList';
import AnnouncementsList from './AnnouncementsList'; // Import the new component
import useFetchWordPressArticles from '../../hooks/useFetchWordPressArticles';
import faqsData from '../data/faqs.json'; // Import FAQs data

/**
 * Interface for static guides.
 */
interface GuideItem {
  id: string;
  title: string;
  category: 'how-to' | 'faq' | 'announcements';
  content: string;
  isPinned?: boolean;
  link?: string;
  featured_media_url?: string;
}

/**
 * Interface for articles fetched from WordPress.
 */
interface Article {
  id: string; // Changed to string for consistency
  title: string;
  category: 'articles';
  excerpt: string;
  content: string;
  link: string;
  featured_media_url?: string;
  date: string;
}

/**
 * Union type for all guide items.
 */
type CombinedGuide = GuideItem | Article;

/**
 * Type guard to determine if a CombinedGuide is an Article.
 * @param guide - The guide item to check.
 * @returns True if the guide is an Article, false otherwise.
 */
const isArticle = (guide: CombinedGuide): guide is Article => guide.category === 'articles';

const TrailGuide: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0); // Default to "FAQs" tab after commenting out "How-To's"
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const { wordpressArticles, loading, error } = useFetchWordPressArticles();

  /**
   * Sample static guide data without 'excerpt' and 'date'.
   */
  const staticGuideData: GuideItem[] = [
    {
      id: '1',
      title: 'Getting Started with Door Hangers',
      category: 'how-to',
      content: 'Learn how to create and manage your first door hanger campaign...',
      isPinned: true,
    },
    {
      id: '2',
      title: 'Managing Your Templates',
      category: 'how-to',
      content: 'Understand how to create and customize quote templates...',
    },
    {
      id: '3',
      title: 'How do I add custom services?',
      category: 'faq',
      content: 'Find out how to add and manage custom services in your templates...',
    },
    {
      id: '4',
      title: 'New Feature: Video Messages',
      category: 'announcements',
      content: 'Introducing our new video message feature for quotes...',
    },
  ];

  /**
   * Import and map FAQs to GuideItem format.
   */
  const faqGuideData: GuideItem[] = faqsData.faqs.flatMap((faqCategory, categoryIndex) =>
    faqCategory.questions.map((faq, questionIndex) => ({
      id: `faq-${categoryIndex}-${questionIndex}`, // Ensure unique IDs
      title: faq.question,
      category: 'faq',
      content: faq.answer,
    }))
  );

  /**
   * Map WordPress articles to the Article interface.
   */
  const mappedWordPressArticles: Article[] = wordpressArticles.map((article) => ({
    id: `wp-${article.id}`, // Prefix to avoid ID conflicts
    title: article.title,
    category: 'articles',
    content: article.excerpt, // Assuming 'excerpt' is desired as 'content'
    excerpt: article.excerpt,
    link: article.link,
    featured_media_url: article.featured_media_url,
    date: article.date,
  }));

  /**
   * Combine static guides, FAQs, and fetched articles.
   */
  const combinedGuides: CombinedGuide[] = [
    ...staticGuideData,
    ...faqGuideData,
    ...mappedWordPressArticles,
  ];

  /**
   * Handle tab changes.
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setSearchQuery(''); // Reset search when changing tabs
    setExpandedItem(null); // Collapse any open items
  };

  /**
   * Handle guide item clicks to expand/collapse content.
   */
  const handleItemClick = (itemId: string) => {
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };

  /**
   * Filter guides based on search query and current tab.
   */
  const filterGuides = () => {
    if (searchQuery.trim() === '') {
      // No search query, filter based on current tab
      return combinedGuides.filter((item) => {
        if (isArticle(item)) {
          return currentTab === 2; // "Articles" tab now has index 2
        }
        switch (item.category) {
          case 'faq':
            return currentTab === 0; // "FAQs" tab has index 0
          case 'announcements':
            return currentTab === 1; // "Announcements" tab has index 1
          default:
            return false;
        }
      });
    } else {
      // Search query exists, filter across all categories
      return combinedGuides.filter((item) => {
        const matchesSearch =
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.content.toLowerCase().includes(searchQuery.toLowerCase());

        return matchesSearch;
      });
    }
  };

  /**
   * Separate articles for the "Articles" tab and sort them by date descending.
   */
  const articles: Article[] = combinedGuides
    .filter(isArticle)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const filteredGuides = filterGuides();

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 64px)',
        bgcolor: 'background.default',
        position: 'relative',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      {/* Main Content Area */}

      <Box sx={{ width: '100%', p: 2, overflowY: 'auto' }}>
        {/* Header */}
        <Typography variant="h4" align="center" gutterBottom>
          Trail Guide
        </Typography>
        <Typography variant="subtitle1" align="center" sx={{ mb: 4, color: 'text.secondary' }}>
          Your path to mastering CowboyQuote
        </Typography>

        {/* Search Bar */}
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

        {/* Navigation Tabs */}
        <NavigationTabs currentTab={currentTab} handleTabChange={handleTabChange} />

        {/* Loading & Error States */}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress color="warning" />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ my: 4 }}>
            {error}
          </Alert>
        )}

        {/* Guide Content */}
        {!loading && !error && searchQuery.trim() !== '' && (
          <GuideList
            guides={filteredGuides}
            expandedItem={expandedItem}
            handleItemClick={handleItemClick}
            isSearchResults={true} // Indicate that this is a search result
          />
        )}

        {/* FAQs Tab Content */}
        {!loading && !error && searchQuery.trim() === '' && currentTab === 0 && (
          <FAQList searchQuery={searchQuery} />
        )}

        {/* Announcements Tab Content */}
        {!loading && !error && searchQuery.trim() === '' && currentTab === 1 && (
          <AnnouncementsList /> // Render the new AnnouncementsList component
        )}

        {/* Articles Tab Content */}
        {!loading && !error && searchQuery.trim() === '' && currentTab === 2 && (
          <ArticleCards articles={articles} />
        )}
      </Box>
    </Box>
  );
};

export default TrailGuide;