// frontend/src/components/NotificationsManager.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Grid,
  Paper,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Notifications as NotificationsIcon,
  Schedule as ScheduleIcon,
  Business as BusinessIcon,
} from '@mui/icons-material';
import adminAxiosInstance from '../utils/adminAxiosConfig';
import { useSnackbar } from 'notistack';

interface Notification {
  _id: string;
  title: string;
  message: string;
  type: 'ANNOUNCEMENT' | 'MAINTENANCE' | 'FEATURE' | 'ALERT';
  targetCompanies: string[];
  isGlobal: boolean;
  expiresAt: string | null;
  createdAt: string;
}

const NOTIFICATION_TYPES = [
  { value: 'ANNOUNCEMENT', label: 'Announcement', color: 'warning' },
  { value: 'MAINTENANCE', label: 'Maintenance', color: 'info' },
  { value: 'FEATURE', label: 'New Feature', color: 'success' },
  { value: 'ALERT', label: 'Alert', color: 'error' },
] as const;

const NotificationsManager: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [availableCompanies, setAvailableCompanies] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [sendToAll, setSendToAll] = useState(false);
  
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    type: 'ANNOUNCEMENT' as const,
    expiresAt: '',
    targetCompanies: [] as string[],
    isGlobal: false,
  });

  useEffect(() => {
    fetchNotifications();
    fetchCompanies();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await adminAxiosInstance.get('/api/admin/notifications');
      setNotifications(response.data);
    } catch (error) {
      enqueueSnackbar('Error fetching notifications', { variant: 'error' });
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await adminAxiosInstance.get('/api/admin/dashboard-data');
      setAvailableCompanies(response.data.companies.map((company: any) => company.companyName));
    } catch (error) {
      enqueueSnackbar('Error fetching companies', { variant: 'error' });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await adminAxiosInstance.post('/api/admin/notifications/new', {
        ...formData,
        targetCompanies: sendToAll ? [] : formData.targetCompanies,
        isGlobal: sendToAll,
      });
      setOpenDialog(false);
      setFormData({
        title: '',
        message: '',
        type: 'ANNOUNCEMENT',
        expiresAt: '',
        targetCompanies: [],
        isGlobal: false,
      });
      setSendToAll(false);
      fetchNotifications();
      enqueueSnackbar('Notification created successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error creating notification', { variant: 'error' });
    }
  };

  const handleDelete = async (notificationId: string) => {
    try {
      await adminAxiosInstance.delete(`/api/admin/notifications/delete/${notificationId}`);
      fetchNotifications();
      enqueueSnackbar('Notification deleted successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error deleting notification', { variant: 'error' });
    }
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleSendToAllToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendToAll(event.target.checked);
    if (event.target.checked) {
      setFormData(prev => ({
        ...prev,
        targetCompanies: [],
      }));
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 'lg', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <NotificationsIcon /> Notifications Manager
        </Typography>
        <Button
          variant="contained"
          color="warning"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{width:500}}
        >
          Create Notification
        </Button>
      </Box>

      {/* Create Notification Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create New Notification</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sendToAll}
                    onChange={handleSendToAllToggle}
                    color="warning"
                  />
                }
                label="Send to All Companies"
                sx={{
                  // Target the Switch's base
                  '& .MuiSwitch-switchBase': {
                    color: 'warning.main', // Unchecked thumb color
                    '&.Mui-checked': {
                      color: 'warning.main', // Checked thumb color
                      '& + .MuiSwitch-track': {
                        backgroundColor: 'warning.main', // Checked track color
                      },
                    },
                  },
                  // Target the Switch's track
                  '& .MuiSwitch-track': {
                    backgroundColor: 'grey.100', // Unchecked track color
                  },
                  color: 'warning.main'
                }}
              />

              <TextField
                label="Title"
                fullWidth
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'warning.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'warning.main',
                  },
                }}
              />

              <TextField
                label="Message"
                fullWidth
                multiline
                rows={4}
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'warning.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'warning.main',
                  },
                }}
              />

              <FormControl fullWidth>
                <InputLabel sx={{ '&.Mui-focused': { color: 'warning.main' } }}>
                  Type
                </InputLabel>
                <Select
                  value={formData.type}
                  onChange={(e) => setFormData({ ...formData, type: e.target.value as typeof formData.type })}
                  label="Type"
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'warning.main',
                    },
                  }}
                >
                  {NOTIFICATION_TYPES.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth disabled={sendToAll}>
                <InputLabel sx={{ '&.Mui-focused': { color: 'warning.main' } }}>
                  Target Companies
                </InputLabel>
                <Select
                  multiple
                  value={formData.targetCompanies}
                  onChange={(e) => setFormData({
                    ...formData,
                    targetCompanies: typeof e.target.value === 'string' 
                      ? e.target.value.split(',') 
                      : e.target.value,
                  })}
                  input={<OutlinedInput label="Target Companies" />}
                  renderValue={(selected) => selected.join(', ')}
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'warning.main',
                    },
                  }}
                >
                  {availableCompanies.map((company) => (
                    <MenuItem key={company} value={company}>
                      <Checkbox checked={formData.targetCompanies.indexOf(company) > -1} />
                      <ListItemText primary={company} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Expires At"
                type="datetime-local"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'warning.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'warning.main',
                  },
                }}
                value={formData.expiresAt}
                onChange={(e) => setFormData({ ...formData, expiresAt: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} variant='contained' color='info'>Cancel</Button>
            <Button type="submit" variant="contained" color="warning">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Notifications List */}
      <Grid container spacing={3}>
        {notifications.map((notification) => (
          <Grid item xs={12} key={notification._id}>
            <Paper elevation={3} sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                <IconButton
                  color="error"
                  onClick={() => handleDelete(notification._id)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <CardContent>
                <Box sx={{ mb: 2 }}>
                  <Chip
                    label={notification.type}
                    color={NOTIFICATION_TYPES.find(t => t.value === notification.type)?.color || 'default'}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                  {notification.isGlobal && (
                    <Chip
                      label="Global"
                      color="warning"
                      size="small"
                      sx={{ ml: 1, mb: 1 }}
                    />
                  )}
                  <Typography variant="h6" gutterBottom>
                    {notification.title}
                  </Typography>
                  <Typography color="textSecondary" paragraph>
                    {notification.message}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <ScheduleIcon fontSize="small" color="action" />
                    <Typography variant="body2" color="textSecondary">
                      Created: {formatDate(notification.createdAt)}
                    </Typography>
                  </Box>
                  {notification.expiresAt && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <ScheduleIcon fontSize="small" color="action" />
                      <Typography variant="body2" color="textSecondary">
                        Expires: {formatDate(notification.expiresAt)}
                      </Typography>
                    </Box>
                  )}
                </Box>

                {!notification.isGlobal && notification.targetCompanies?.length > 0 && (
                  <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <BusinessIcon fontSize="small" color="action" />
                    <Typography variant="body2" color="textSecondary">
                      Target Companies: {notification.targetCompanies.join(', ')}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NotificationsManager;