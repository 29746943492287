import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell
} from 'recharts';
import { Card, CardContent, Typography, Box, Button, CircularProgress } from '@mui/material';
import { Person, Timeline, Speed } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { useAuth } from '../AuthContext';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import UserChip from './UserChip';

interface AuthUser {
  role?: string;
}

interface UserPerformance {
  userId: string;
  name: string;
  userColor: string;
  performance: {
    quoteCount: number;
    acceptedQuoteCount: number;
    viewedQuoteCount: number;
    averageResponseTime: number;
  };
}

interface ChartData {
  userId: string;
  name: string;
  value: number;
  userColor: string;
}

interface Achievement {
  type: 'MOST_ACTIVE' | 'SHARPSHOOTER' | 'QUICK_DRAW';
  displayName: string;
  description: string;
  icon: React.ReactNode;
  currentLeader?: {
    userId: string;
    userName: string;
    userColor: string;
    value: number;
  };
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: ChartData;
    unit?: string;
  }>;
  label?: string;
}


const TheRoundup: React.FC = () => {
  const { user } = useAuth() as { user: AuthUser | null };
  const { enqueueSnackbar } = useSnackbar();
  
  const [users, setUsers] = useState<UserPerformance[]>([]);
  const [loading, setLoading] = useState(true);

  const [achievements, setAchievements] = useState<Achievement[]>([
    {
      type: 'MOST_ACTIVE',
      displayName: 'Most Active Cowboy',
      description: 'Highest number of quotes this month',
      icon: <Person sx={{ fontSize: 40 }} />,
    },
    {
      type: 'SHARPSHOOTER',
      displayName: 'Sharpshooter',
      description: 'Best acceptance rate this month',
      icon: <Timeline sx={{ fontSize: 40 }} />,
    },
    {
      type: 'QUICK_DRAW',
      displayName: 'Quick Draw',
      description: 'Fastest average response time',
      icon: <Speed sx={{ fontSize: 40 }} />,
    },
  ]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Use different endpoints based on role
      const endpoint = user?.role === 'Owner' ? '/api/users/company' : '/api/users/company-metrics';
      const response = await axiosInstance.get(endpoint);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      enqueueSnackbar('Error fetching user data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDistributeAchievements = async () => {
    try {
      await axiosInstance.post('/api/achievements/distribute');
      enqueueSnackbar('Monthly achievements distributed successfully!', { 
        variant: 'success' 
      });
      fetchUsers(); // refresh data after distribution
    } catch (error) {
      console.error('Error distributing achievements:', error);
      enqueueSnackbar('Failed to distribute achievements', { 
        variant: 'error' 
      });
    }
  };

  // Recalculate achievements whenever users changes
  useEffect(() => {
    const updatedAchievements = achievements.map((achievement) => {
      // If there are no users, skip reduce logic
      if (users.length === 0) {
        return achievement;
      }

      let currentLeader;

      switch (achievement.type) {
        case 'MOST_ACTIVE':
          currentLeader = users.reduce((prev, current) =>
            current.performance.quoteCount >
            (prev?.performance.quoteCount || 0)
              ? current
              : prev
          );
          return {
            ...achievement,
            currentLeader: currentLeader
              ? {
                  userId: currentLeader.userId,
                  userName: currentLeader.name,
                  userColor: currentLeader.userColor,
                  value: currentLeader.performance.quoteCount,
                }
              : undefined,
          };

        case 'SHARPSHOOTER':
          currentLeader = users.reduce((prev, current) => {
            const currentRate =
              current.performance.quoteCount > 0
                ? (current.performance.acceptedQuoteCount /
                    current.performance.quoteCount) * 100
                : 0;
            const prevRate =
              prev?.performance.quoteCount > 0
                ? (prev.performance.acceptedQuoteCount /
                    prev.performance.quoteCount) * 100
                : 0;
            return currentRate > prevRate ? current : prev;
          });
          return {
            ...achievement,
            currentLeader: currentLeader
              ? {
                  userId: currentLeader.userId,
                  userName: currentLeader.name,
                  userColor: currentLeader.userColor,
                  value:
                    currentLeader.performance.quoteCount > 0
                      ? (currentLeader.performance.acceptedQuoteCount /
                          currentLeader.performance.quoteCount) *
                        100
                      : 0,
                }
              : undefined,
          };

        case 'QUICK_DRAW':
          currentLeader = users.reduce((prev, current) =>
            current.performance.averageResponseTime <
              (prev?.performance.averageResponseTime || Infinity) &&
            current.performance.averageResponseTime > 0
              ? current
              : prev
          );
          return {
            ...achievement,
            currentLeader: currentLeader
              ? {
                  userId: currentLeader.userId,
                  userName: currentLeader.name,
                  userColor: currentLeader.userColor,
                  value: currentLeader.performance.averageResponseTime,
                }
              : undefined,
          };

        default:
          return achievement;
      }
    });

    setAchievements(updatedAchievements);
  }, [users, achievements]);

  // Prepare chart data
  const getChartData = (achievementType: Achievement['type']): ChartData[] => {
    return users
      .map((user) => {
        let value = 0;
        switch (achievementType) {
          case 'MOST_ACTIVE':
            value = user.performance.quoteCount;
            break;
          case 'SHARPSHOOTER':
            value =
              user.performance.quoteCount > 0
                ? (user.performance.acceptedQuoteCount / user.performance.quoteCount) * 100
                : 0;
            break;
          case 'QUICK_DRAW':
            value = user.performance.averageResponseTime || 0;
            break;
        }
        return {
          userId: user.userId,
          name: user.name,
          value,
          userColor: user.userColor,
        };
      })
      .sort((a, b) =>
        achievementType === 'QUICK_DRAW' ? a.value - b.value : b.value - a.value
      );
  };

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { name } = payload[0].payload;
      const { value, unit } = payload[0];
      return (
        <Box
          sx={{
            backgroundColor: 'background.paper',
            p: 1.5,
            border: 1,
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="body2">
            {unit === '%'
              ? `${value.toFixed(1)}%`
              : unit === 'm'
              ? `${value.toFixed(0)}m`
              : value}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const renderLabel = (achievementType: Achievement['type'], value: number) => {
    switch (achievementType) {
      case 'QUICK_DRAW':
        return `${value.toFixed(0)}m`;
      case 'SHARPSHOOTER':
        return `${value.toFixed(1)}%`;
      default:
        return value.toString();
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 200,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If no users exist, show a message
  if (users.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">No user data available.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Title + Distribute button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4, mt:4}}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          The Roundup
        </Typography>
        {user?.role === 'Owner' && (
          <Button
            variant="contained"
            size='small'
        
            color="warning"
            onClick={handleDistributeAchievements}
            sx={{ textTransform: 'none',width:200 }}
          >
            Distribute Monthly Achievements
          </Button>
        )}
      </Box>

      {/* Achievements */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
        {achievements.map((achievement) => {
          const data = getChartData(achievement.type);
          return (
            <Card
              key={achievement.type}
              sx={{
                flex: 1,
                minWidth: 300,
                position: 'relative',
                overflow: 'visible',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                },
              }}
            >
              <CardContent>
                {/* Header */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        alpha(theme.palette.warning.main, 0.1),
                      borderRadius: 1,
                      p: 1,
                      mr: 2,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {achievement.icon}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {achievement.displayName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {achievement.description}
                    </Typography>
                  </Box>
                </Box>

                {/* Current Leader */}
                {achievement.currentLeader && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Current Leader:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <UserChip
                        userId={achievement.currentLeader.userId}
                        userName={achievement.currentLeader.userName}
                        userColor={achievement.currentLeader.userColor}
                      />
                      <Typography variant="body2">
                        {renderLabel(achievement.type, achievement.currentLeader.value)}
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Chart */}
                <Box sx={{ height: 200 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={data}
                      margin={{ top: 20, right: 10, left: -20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" hide />
                      <YAxis
                        domain={
                          achievement.type === 'QUICK_DRAW'
                            ? ['dataMin', 'dataMax']
                            : [0, 'dataMax']
                        }
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="value" fill="#8884d8">
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.userColor || '#666666'}
                          />
                        ))}
                        <LabelList
                          dataKey="value"
                          position="top"
                          formatter={(value: number) => renderLabel(achievement.type, value)}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default TheRoundup;